import React from 'react';
import './footer.scss';
import hxlogo from '../../assets/images/hxlogo.png';
import dclogo from '../../assets/images/footer-images/discord-logo.png';
import fblogo from '../../assets/images/footer-images/facebooks-logo.png';
import gmlogo from '../../assets/images/footer-images/gmails-logo.png';
import iglogo from '../../assets/images/footer-images/instagram-logo.png';
import ttlogo from '../../assets/images/footer-images/tiktok-logo.png';
import ytlogo from '../../assets/images/footer-images/youtube-logo.png';

const Footer = () => {
  return (
    <div className='footer__container'>
      <div className='footer__columns'>
        <div className='footer__column footer__branding'>
          <img className='footer__logo' src={"https://cdn.hexaverse.hu/hxlogo.png"} alt="HexaVerse logo" />
          <div className='footer__branding'>
          <p className='footer__motto'> Te megálmodod, közösen megvalósítjuk</p>
          </div>
          <div className='footer__socials'>
            <a href="https://dc.hexaverse.hu" target="_blank" rel="noopener noreferrer">
              <img src={dclogo} alt="Discord logo" className="footer__social-icon" />
            </a>
            <a href="https://www.youtube.com/@HexaVerseHosting" target="_blank" rel="noopener noreferrer">
              <img src={ytlogo} alt="YouTube logo" className="footer__social-icon" />
            </a>
            <a href="https://www.instagram.com/hexaverse_szerver_hoszting" target="_blank" rel="noopener noreferrer">
              <img src={iglogo} alt="Instagram logo" className="footer__social-icon" />
            </a>
            <a href="https://www.tiktok.com/hexaversehosting" target="_blank" rel="noopener noreferrer">
              <img src={ttlogo} alt="TikTok logo" className="footer__social-icon" />
            </a>
            <a href="https://www.facebook.com/profile.php?id=61556078564819" target="_blank" rel="noopener noreferrer">
              <img src={fblogo} alt="Facebook logo" className="footer__social-icon" />
            </a>
            <a href="mailto:info@hexaverse.hu">
              <img src={gmlogo} alt="Gmail logo" className="footer__social-icon" />
            </a>
          </div>
        </div>
        <div className='footer__column'>
          <h4>Szolgáltatások</h4>
          <ul>
            <li><a className="gray__footer__link" href="https://szerverek.hexaverse.hu" target="_blank" rel="noopener noreferrer">Játékszerverek</a></li>
            {/*<li><a href="https://valami.hexaverse.hu" target="_blank" rel="noopener noreferrer">Weboldalak</a></li>*/}
            <li><a className="gray__footer__link" href="/game/vps" target="_blank" rel="noopener noreferrer">VPS</a></li>
          </ul>
        </div>
        <div className='footer__column'>
          <h4>Információk</h4>
          <ul>
            <li><a className="gray__footer__link" href="/verziok" rel="noopener noreferrer">Minecraft verziók</a></li>
            <li><a className="gray__footer__link" href="https://dc.hexaverse.hu" target="_blank" rel="noopener noreferrer">Discord szerverünk</a></li>
            <li><a className="gray__footer__link" href="https://status.hexaverse.hu" target="_blank" rel="noopener noreferrer">Szervergép státuszok</a></li>
          </ul>
        </div>
        <div className='footer__column'>
          <h4>Ügyfeleknek</h4>
          <ul>
            <li><a className="gray__footer__link" href="/auth/registration" target="_blank" rel="noopener noreferrer">Regisztráció</a></li>
            <li><a className="gray__footer__link" href="/auth/login" target="_blank" rel="noopener noreferrer">Bejelentkezés</a></li>
            <li><a className="gray__footer__link" href="/szolgaltatasaim" target="_blank" rel="noopener noreferrer">Bérelt szolgáltatások</a></li>
          </ul>
        </div>
        <div className='footer__column'>
          <h4>Egyebek</h4>
          <ul>
            <li><a className="gray__footer__link" href="https://hexaverse.hu/rolunk" rel="noopener noreferrer">Rólunk</a></li>
            <li><a className="gray__footer__link" href="https://www.minecraft.net/en-us/eula" target="_blank" rel="noopener noreferrer">Minecraft EULA</a></li>
            <li><a className="gray__footer__link" href="https://hexaverse.hu/AT.pdf" target="_blank" rel="noopener noreferrer">Adatkezelési Tájékoztató</a></li>
            <li><a className="gray__footer__link" href="https://hexaverse.hu/aszf" target="_blank" rel="noopener noreferrer">ÁSZF</a></li>
          </ul>
        </div>
      </div>
      <div className='footer__bottom'>
        <p className= 'copyright'>© 2024 HexaVerse. Minden jog fenntartva.</p>
      </div>
    </div>
  );
};

export default Footer;
