import React, { useState } from 'react';
import Navbar from '../../components/Navbar/Navbar.jsx';
import Footer from '../../components/Footer/Footer.jsx';
import './egyenlegfeltoltes.scss'; // Import the SCSS file here
import stripe from '../../assets/images/Group_81.png';
import bank from '../../assets/images/Group_80.png';
import paypal from '../../assets/images/Group_79.png';
import { useNavigate } from 'react-router-dom'

const Egyenlegfeltoltes = () => {
  const [stage, setStage] = useState(1);
  const [isCompany, setIsCompany] = useState(false);
  const [taxNumber, setTaxNumber] = useState('');
  const [isValid, setIsValid] = useState(true);
  const [amount, setAmount] = useState('');
  const [paymentMethod, setPaymentMethod] = useState('');
  const [error, setError] = useState('');
  const [showBankWarning, setShowBankWarning] = useState(false); // State for the bank warning visibility

  const handleNextStage = () => {
    if (stage === 1 && (!amount || parseInt(amount) < 500)) {
      setError('A minimum összeg 500 Ft.');
      return;
    }
    if (stage === 2 && !paymentMethod) {
      setError('Válasszon fizetési módot.');
      return;
    }
    if (stage === 3 && isCompany && (!taxNumber || !isValid)) {
      setError('Adjon meg érvényes adószámot.');
      return;
    }
    setError('');
    setStage((prevStage) => prevStage + 1);
  };

  const handlePreviousStage = () => {
    setError('');
    setStage((prevStage) => prevStage - 1);
  };

  const handleCheckboxChange = () => {
    setIsCompany(!isCompany);
    setTaxNumber('');
    setIsValid(true);
  };

  const handleAmountChange = (e) => {
    const value = e.target.value.replace(/\D/g, '');
    setAmount(value);
  };

  const handlePaymentSelect = (method) => {
    setPaymentMethod(method);
  };

  const navigate = useNavigate();
  const onClickEgyenlegem = () => {
    navigate("/egyenlegem")
  }

  const formatTaxNumber = (value) => {
    const numericValue = value.replace(/\D/g, '').slice(0, 12);
    let formattedValue;
    if (numericValue.length <= 8) {
      formattedValue = numericValue.replace(/(\d{4})(\d{4})?/, '$1-$2').trim();
    } else if (numericValue.length <= 10) {
      formattedValue = numericValue.replace(/(\d{4})(\d{3})(\d{3})?/, '$1-$2-$3').trim();
    } else {
      formattedValue = numericValue.replace(/(\d{5})(\d{4})(\d{3})?/, '$1-$2-$3').trim();
    }
    return formattedValue;
  };

  const handleTaxNumberChange = (e) => {
    const formattedValue = formatTaxNumber(e.target.value);
    setTaxNumber(formattedValue);

    const isValidLength =
      formattedValue.replace(/-/g, '').length >= 8 &&
      formattedValue.replace(/-/g, '').length <= 12;
    setIsValid(isValidLength);
  };

  const handleSubmit = () => {
    if (isCompany && (!taxNumber || taxNumber.replace(/-/g, '').length < 8 || taxNumber.replace(/-/g, '').length > 12)) {
        setIsValid(false);
        return;
    } else if(paymentMethod === "Stripe"){
        createStripeSession()
        return
    } else if(paymentMethod === "PayPal"){
        createPaypalSession()
        return;
    }
    alert('Sikeres egyenlegfeltöltés!');
    setStage(1);
    setAmount('');
    setPaymentMethod('');
    setIsCompany(false);
    setTaxNumber('');
  };

  const toggleBankWarning = () => {
    setShowBankWarning(!showBankWarning); // Toggle the bank warning visibility
  };
  const createStripeSession = () => {
    const token = localStorage.getItem("F49w'2jTP:mE6C/fJQXA,G")
    if(!token){
      return
    }
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${token}`);

    const raw = JSON.stringify({
      "amount": amount,
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow"
    };

    fetch("https://api.hexaverse.hu/api/v1/payment/buy/stripe", requestOptions)
      .then(async (response) => {
        const responseJson = await response.json()
        const url = responseJson.url;
        if(!url && !url.startsWith("https://")) return
        window.open(url)
      })
      .then((result) => console.log(result))
      .catch((error) => console.error(error));
  }

  const createPaypalSession = () => {
    const token = localStorage.getItem("F49w'2jTP:mE6C/fJQXA,G")
    if(!token){
      return
    }
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${token}`);

    const raw = JSON.stringify({
      "amount": amount,
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow"
    };

    fetch("https://api.hexaverse.hu/api/v1/payment/buy/paypal", requestOptions)
      .then(async (response) => {
        const responseJson = await response.json()
        const url = responseJson.url;
        if(!url && !url.startsWith("https://")) return
        window.open(url)
      })
      .then((result) => console.log(result))
      .catch((error) => console.error(error));
  }
  
  return (
    <div>
      <Navbar />
      <div className="payment__container__final">
        <h1>Egyenlegfeltöltés</h1>
        {error && <p className="error-message text-error-color">{error}</p>}

        {stage === 1 && (
          <div className="stage-content active">
            <input
              type="text"
              value={amount}
              onChange={handleAmountChange}
              placeholder="Egyéni összeg [ Forint ]"
              className="amount-input"
            />
            <p className="payment-white-info">A fizetéssel bele egyezel abba, hogy elolvastad és megértetted az Adatkezelési tájékoztatóban és ÁSZF-ben összefoglaltakat, valamint elfogadod, hogy a fizetés után a kiküldött számlát kinyomtatással tudod érvényesíteni.</p>
            <div className="button-group">
              <button onClick={onClickEgyenlegem} type="button" className="continue-button">
                Vissza
              </button>
              <button onClick={handleNextStage} type="button" className="continue-button">
                Tovább
              </button>
            </div>
          </div>
        )}

        {stage === 2 && (
          <div className="stage-content active">
            <h2>Válasszon fizetési módot</h2>
            <div className="payment__card__container">
              <img
                src={stripe}
                alt="Stripe"
                className={`payment__cards ${paymentMethod === 'Stripe' ? 'selected_a' : ''}`}
                onClick={() => handlePaymentSelect('Stripe')}
              />
              <img
                src={bank}
                alt="Bank"
                className={`payment__cards ${paymentMethod === 'Banki átutalás' ? 'selected_b' : ''}`}
                onClick={() => handlePaymentSelect('Banki átutalás')}
              />
              <img
                src={paypal}
                alt="PayPal"
                className={`payment__cards ${paymentMethod === 'PayPal' ? 'selected_c' : ''}`}
                onClick={() => handlePaymentSelect('PayPal')}
              />
            </div>

            <label className="animated-checkbox-label">
              <input type="checkbox" checked={isCompany} onChange={handleCheckboxChange} />
              <span className="custom-checkbox"></span>
              Cégként vásárolok
            </label>

            <div className="button-group">
              <button onClick={handlePreviousStage} type="button" className="continue-button">
                Vissza
              </button>
              <button onClick={handleNextStage} type="button" className="continue-button">
                Tovább
              </button>
            </div>
          </div>
        )}

        {stage === 3 && (
          <div className="stage-content active">
            <h2>Összegzés</h2>
            <div className="payment-summary">
              <p>Összeg: {amount} Ft</p>
              <p>Fizetési mód: {paymentMethod}</p>
            </div>

            {paymentMethod === 'Banki átutalás' && (
              <div className="bank-details">
                <h3 className='bank-imggg'>
                  Banki Átutalás Részletei
                  <button className="warning-icon-button" onClick={toggleBankWarning}>
                    <img src="https://cdn-icons-png.flaticon.com/512/179/179386.png" alt="Warning" />
                  </button>
                </h3>
                {showBankWarning && (
                  <div className="bank-warning">
                    <span>A banki átutalás manuális folyamat, amely hosszabb időt vehet igénybe.</span>
                  </div>
                )}
                <p>
                  <strong>Számlaszám:</strong> 11618003-32963201-18000009
                </p>
                <p>
                  <strong>Kedvezményezett neve:</strong> Koltai Tamás
                </p>
                <p>
                  <strong>Közlemény:</strong> {amount} Ft befizetés
                </p>
              </div>
            )}

            {isCompany && (
              <div className="tax-number-input">
                <input
                  type="text"
                  value={taxNumber}
                  onChange={handleTaxNumberChange}
                  placeholder="Adószám"
                  className={!isValid ? 'error' : ''}
                />
                {!isValid && <p className="error-message">Kérjük, adjon meg érvényes adószámot.</p>}
              </div>
            )}

            <div className="button-group">
              <button onClick={handlePreviousStage} type="button" className="continue-button">
                Vissza
              </button>
              <button onClick={handleSubmit} type="button" className="continue-button">
                Feltöltés
              </button>
            </div>
          </div>
        )}
      </div>
      <Footer />
    </div>
  );
};

export default Egyenlegfeltoltes;