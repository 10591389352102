import React, { useState, useEffect } from 'react';
import './Gsbase.scss';
import Navbar from '../../components/Navbar/Navbar.jsx';
import Footer from '../../components/Footer/Footer.jsx';
import gmod_banner from '../../assets/images/garrysmod-banner.png';

const Garrysmod = () => {
  const [ram, setRam] = useState(2);
  const [storage, setStorage] = useState(15);
  const [cpu, setCpu] = useState(1.5);
  const [serverType, setServerType] = useState('Vanilla');
  const [serverVersion, setServerVersion] = useState('1.21.1');
  const [serverName, setServerName] = useState('');
  const [steamid, setSteamID] = useState('');
  const [couponCode, setCouponCode] = useState('');
  const [isTypeOpen, setIsTypeOpen] = useState(false);
  const [isVersionOpen, setIsVersionOpen] = useState(false);
  const [expirationDate, setExpirationDate] = useState('');
  useEffect(() => {
    const currentDate = new Date();
    currentDate.setDate(currentDate.getDate() + 30);
    setExpirationDate(currentDate.toLocaleDateString('hu-HU'));
  }, []);
 
  const calculateCost = () => {
    const ramCost = ram >= 2 ? ram * 500 : ram * 500;
    const storageCost = storage > 15 ? (storage - 15) * 25 : 0;
    const cpuCost = cpu <= 1.5 ? 0 : Math.ceil((cpu - 1.5) * 2) * 250;
    return ramCost + storageCost + cpuCost;
 
 };


  const toggleTypeDropdown = () => setIsTypeOpen(!isTypeOpen);
  const toggleVersionDropdown = () => setIsVersionOpen(!isVersionOpen);

  const handleTypeSelection = (type) => {
    setServerType(type);
    setIsTypeOpen(false);
  };

  const handleVersionSelection = (version) => {
    setServerVersion(version);
    setIsVersionOpen(false);
  };
    
  return (
    <div>
      <Navbar />
      <div className='gs__container'>
        <img className='gs__banner' src={gmod_banner} alt="kep1" />
      </div>

      <div className='config-summary-container'>
        {/* Server Configuration Section */}
        <div className='configuration-section'>
          <h1>Szerver konfiguráció</h1>

          <div className="slider-wrapper">
            <label className='white-text'>
              Memória <span className='sublabel'>- {ram}GB</span>
            </label>
            <div className='slider-container'>
              <input
                type="range"
                min="2"
                max="6"
                value={ram}
                onChange={(e) => setRam(parseInt(e.target.value))}
                className='slider garrysmod-slider_a'
              />
            </div>
            <div className='slider-container-buttons'>
              <button onClick={() => setRam(Math.max(2, ram - 1))}>-</button>
              <button onClick={() => setRam(Math.min(6, ram + 1))}>+</button>
            </div>
          </div>

          <div className="slider-wrapper">
            <label className='white-text'>
              Tárhely <span className='sublabel'>- {storage}GB</span>
            </label>
            <div className='slider-container'>
              <input
                type="range"
                min="15"
                max="30"
                value={storage}
                onChange={(e) => setStorage(parseInt(e.target.value))}
                className='slider garrysmod-slider_b'
              />
            </div>
            <div className='slider-container-buttons'>
              <button onClick={() => setStorage(Math.max(10, storage - 1))}>-</button>
              <button onClick={() => setStorage(Math.min(30, storage + 1))}>+</button>
            </div>
          </div>

          <div className="slider-wrapper">
            <label className='white-text'>
              Processzor szálak <span className='sublabel'>- {cpu}</span>
            </label>
            <div className='slider-container'>
            <input
              type="range"
              min="1.5"
              max="3"
              step="0.5"
              value={cpu}
              onChange={(e) => setCpu(parseFloat(e.target.value))}
              className='slider garrysmod-slider_c'
            />
            </div>
            <div className='slider-container-buttons'>
              <button onClick={() => setCpu(Math.max(1.5, cpu - 0.5))}>-</button>
              <button onClick={() => setCpu(Math.min(3, cpu + 0.5))}>+</button>
            </div>
          </div>
          <div className="grey-line"></div>

          <div className='details'>
            <label>Részletek</label>
            <input
              type="text"
              placeholder="Szerver neve"
              value={serverName}
              onChange={(e) => setServerName(e.target.value)}
              className='server-name-input'
            />
            <input
              type="text"
              placeholder="Steam ID"
              value={steamid}
              onChange={(e) => setServerName(e.target.value)}
              className='server-name-input'
            />
          </div>
          <div className="grey-line"></div>

          <div className='tax-note'>! Az árak alanyi adómentesek !</div>
        </div>

        {/* Gradient Divider */}
        <div className='garrysmod-divider'></div>

        {/* Summary Section */}
        <div className='summary-section'>
          <h1>Összegzés</h1>
          <div className="grey-line"></div>
          <div className='summary-content'>
            <p className='white-text'>Memória <span className='sublabel'>- {ram}GB</span></p>
            <p className='white-text'>Tárhely <span className='sublabel'>- {storage}GB</span></p>
            <p className='white-text'>Processzor szálak <span className='sublabel'>- {cpu}</span></p>
            <p className='white-text'>Adatbázisok <span className='sublabel'>- 4</span></p>
            <div className="grey-line"></div>
            <input
              type="text"
              placeholder="Kupon"
              value={couponCode}
              onChange={(e) => setCouponCode(e.target.value)}
              className='coupon-input'
            />
            <div className="grey-line"></div>
            <p className='total-cost'>Összesen: <span className='boldlabel'>{calculateCost()} Ft</span> <span className='sublabel'> / Hó</span></p>
            <span className='sublabel'>Lejárat - {expirationDate}</span>
            <div className="grey-line"></div>
            <button className='rent-button'>Szerver bérlése</button>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );

};

export default Garrysmod;
