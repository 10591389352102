// components/DropdownMenu/DropdownMenu.jsx
import React, { useState, useRef, useEffect } from 'react';
import './droprow.scss';

const DropdownItem = ({ title, content }) => {
  const [isOpen, setIsOpen] = useState(false);
  const contentRef = useRef(null);

  const toggleOpen = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    if (contentRef.current) {
      if (isOpen) {
        contentRef.current.style.maxHeight = `${contentRef.current.scrollHeight}px`;
      } else {
        contentRef.current.style.maxHeight = '0px';
      }
    }
  }, [isOpen]);

  return (
    <div className="dropdown__item">
      <div className="dropdown__title" onClick={toggleOpen}>
        <h3>{title}</h3>
        <span>{isOpen ? '-' : '+'}</span>
      </div>
      <div ref={contentRef} className="dropdown__content">
        <p>{content}</p>
      </div>
    </div>
  );
};

const DropdownMenu = () => {
  const dropdownData = [
    { title: 'Milyen lehetőségeim vannak fizetéskor?', content: 'Rendszerünkben elérhető Stripe, Paypal, Bankszámlás utalás, és hamarosan Paysafe is.' },
    { title: 'Hogyan érhetem el szerveremet?', content: 'Panelunk játékszervereidhez, Kezelőfelületünk pedig VPS-eidhez nyújt online hozzáférést az alapvető SSH és SFTP elérésen kívül.' },
    { title: 'Milyen szolgáltatásokat nyújtunk?', content: 'Játékszerver és VPS ( virtuális privát szerver ) szolgáltatásaink mellett lehetőséged áll nálunk webszervert is bérelni.' },
    { title: 'Van-e pénzvisszatérítési garancia?', content: 'Igen, 14 napig gyakorolhatod elállási jogodat.' },
    { title: 'Hol érhetlek el titeket?', content: 'Elérhetőségeinket a lap alján találhatod.' },
  ];

  return (
    <div className="dropdown__menu">
      <h1>GYIK</h1>
      {dropdownData.map((item, index) => (
        <DropdownItem key={index} title={item.title} content={item.content} />
      ))}
    </div>
  );
};

export default DropdownMenu;
