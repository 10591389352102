let servicesList = [];

let service = {
    service_id: '',
    service_type: '',
    service_name: '',
    memory: '',
    disk: '',
    cpu: '',
    expiresId: ''
};

let userData = {
    username: '',
    coin: 0
};


export const getUniqueUUIDs = () => {
    const uuids = [];
    const uuidPattern = /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/;

    for (let i = 0; i < localStorage.length; i++) {
        const key = localStorage.key(i);
        const uuid = key.split('/')[0];

        if (uuidPattern.test(uuid) && !uuids.includes(uuid)) {
            uuids.push(uuid);
        }
    }

    return uuids;
}




export function setUserData(data) {
    userData = { ...userData, ...data };
    localStorage.setItem("username", userData.username);
    localStorage.setItem("coin", userData.coin);
}

export function setService(data) {
    service = { ...service, ...data };
    if(data !== null) {
        servicesList.push(service);
        const isAlreadyExists = localStorage.getItem(`${data.service_id}/service_name`)
        if(isAlreadyExists){
            return;
        } else {
            localStorage.setItem(`${data.service_id}/service_type`, `${data.service_type}`)
            localStorage.setItem(`${data.service_id}/service_name`, `${data.service_name}`)
            localStorage.setItem(`${data.service_id}/service_id`, `${data.service_id}`)
            localStorage.setItem(`${data.service_id}/ram`, `${data.memory}`)
            localStorage.setItem(`${data.service_id}/disk`, `${data.disk}`)
            localStorage.setItem(`${data.service_id}/cpu`, `${data.cpu}`)
            localStorage.setItem(`${data.service_id}/expires`, `${data.expires_at}`)
            
        }   
    }
}

export function getUserData() {
    // console.log(userData)
    return userData;
}

export function getServicesList() {
    return servicesList;
}

export function getServicesFromBackend() {

    const token = localStorage.getItem("F49w'2jTP:mE6C/fJQXA,G");
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${token}`);

    
    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow"
    };
    
    fetch("https://api.hexaverse.hu/api/v1/services/buy", requestOptions)
      .then(async response => {
        const responseJson = await response.json();
        if (response.ok) {
           /* setService({
                service_id: element.service_id,
                service_type: element.service_type,
                service_name: element.service_name,
                memory: element.memory,
                disk: element.disk,
                cpu: element.cpu,
                expires_at: element.exp
            });*/
        }

      })
      .catch((error) => console.error(error));
}
