import React, { useState, useEffect } from 'react';
import './Gsbase.scss';
import Navbar from '../../components/Navbar/Navbar.jsx';
import Footer from '../../components/Footer/Footer.jsx';

const { buyService } = require("../../payment/paymentFunctions.js")

const Rust = () => {
  const [ram, setRam] = useState(8);
  const [storage, setStorage] = useState(30);
  const [cpu, setCpu] = useState(1.5);
  const [serverType, setServerType] = useState('Vanilla');
  const [serverVersion, setServerVersion] = useState('1.21.1');
  const [serverName, setServerName] = useState('');
  const [couponCode, setCouponCode] = useState('');
  const [isTypeOpen, setIsTypeOpen] = useState(false);
  const [isVersionOpen, setIsVersionOpen] = useState(false);
  const [expirationDate, setExpirationDate] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [fadeOut, setFadeOut] = useState(true);
  const [messageType, setMessageType] = useState('');

  useEffect(() => {
    const currentDate = new Date();
    currentDate.setDate(currentDate.getDate() + 30);
    setExpirationDate(currentDate.toLocaleDateString('hu-HU'));
  }, []);

  const calculateCost = () => {
    const ramCost = ram >= 8 ? ram * 500 : ram * 500;
    const storageCost = storage > 30 ? (storage - 15) * 25 : 0;
    const cpuCost = cpu <= 1.5 ? 0 : Math.ceil((cpu - 1.5) * 2) * 250;
    return ramCost + storageCost + cpuCost;
  };

  const toggleTypeDropdown = () => setIsTypeOpen(!isTypeOpen);
  const toggleVersionDropdown = () => setIsVersionOpen(!isVersionOpen);

  const handleTypeSelection = (type) => {
    setServerType(type);
    setIsTypeOpen(false);
  };

  const handleVersionSelection = (version) => {
    setServerVersion(version);
    setIsVersionOpen(false);
  };

  const handleBuy = () => {
    const isLoggedIn = localStorage.getItem("F49w'2jTP:mE6C/fJQXA,G");
    if (!isLoggedIn || isLoggedIn === undefined || isLoggedIn === null) {
      setErrorMessage("Vásárlás előtt be kell jelentkezned.");
      setMessageType("error");
      setFadeOut(false);
      setTimeout(() => setFadeOut(true), 4000);
      return;
    }

    if (!serverName.trim()) {
      setErrorMessage("Kérlek adj meg egy szerver nevet.");
      setMessageType("error");
      setFadeOut(false);
      setTimeout(() => setFadeOut(true), 4000);
      return;
    }

    const isBalanceSufficient = Math.random() > 0.5;
    if (!isBalanceSufficient) {
      setErrorMessage("Nincs elegendő egyenleg a vásárláshoz.");
      setMessageType("error");
      setFadeOut(false);
      setTimeout(() => setFadeOut(true), 4000);
      return;
    }

    buyService("rust", serverName, ram * 1024, storage * 1024, cpu * 100, couponCode).then((status) => {
      if (status === true) {
        setErrorMessage("A vásárlás sikeresen megtörtént!");
        setMessageType("success");
      } else if (status === 69) {
        setErrorMessage("Jelentkezz be a vásárláshoz.");
        setMessageType("error");
      } else if (status === 911) {
        setErrorMessage("Nincs elég pénzed.");
        setMessageType("error");
      } else {
        setErrorMessage("Hiba történt a vásárlás során. Kérjük, próbáld újra.");
        setMessageType("error");
      }
      setFadeOut(false);
      setTimeout(() => setFadeOut(true), 4000);
    });
  };

  return (
    <div>
      <div className={`green-error-box ${fadeOut ? 'fade-out' : ''}`}>
        <span className={`error-message ${messageType}`}>{errorMessage}</span>
      </div>
      <Navbar />
      <div className='gs__container'>
      </div>

      <div className='config-summary-container'>
        {/* Server Configuration Section */}
        <div className='configuration-section'>
          <h1>Szerver konfiguráció</h1>

          <div className="slider-wrapper">
            <label className='white-text'>
              Memória <span className='sublabel'>- {ram}GB</span>
            </label>
            <div className='slider-container'>
              <input
                type="range"
                min="8"
                max="24"
                value={ram}
                onChange={(e) => setRam(parseInt(e.target.value))}
                className='slider rust-slider_a'
              />
            </div>
            <div className='slider-container-buttons'>
              <button onClick={() => setRam(Math.max(8, ram - 1))}>-</button>
              <button onClick={() => setRam(Math.min(24, ram + 1))}>+</button>
            </div>
          </div>

          <div className="slider-wrapper">
            <label className='white-text'>
              Tárhely <span className='sublabel'>- {storage}GB</span>
            </label>
            <div className='slider-container'>
              <input
                type="range"
                min="30"
                max="128"
                value={storage}
                onChange={(e) => setStorage(parseInt(e.target.value))}
                className='slider rust-slider_b'
              />
            </div>
            <div className='slider-container-buttons'>
              <button onClick={() => setStorage(Math.max(30, storage - 1))}>-</button>
              <button onClick={() => setStorage(Math.min(128, storage + 1))}>+</button>
            </div>
          </div>

          <div className="slider-wrapper">
            <label className='white-text'>
              Processzor szálak <span className='sublabel'>- {cpu}</span>
            </label>
            <div className='slider-container'>
            <input
              type="range"
              min="1.5"
              max="8"
              step="0.5"
              value={cpu}
              onChange={(e) => setCpu(parseFloat(e.target.value))}
              className='slider rust-slider_c'
            />
            </div>
            <div className='slider-container-buttons'>
              <button onClick={() => setCpu(Math.max(1.5, cpu - 1))}>-</button>
              <button onClick={() => setCpu(Math.min(8, cpu + 1))}>+</button>
            </div>
          </div>
          <div className="grey-line"></div>

          <div className='details'>
            <label>Részletek</label>
            <input
              type="text"
              placeholder="Szerver neve"
              value={serverName}
              onChange={(e) => setServerName(e.target.value)}
              className='server-name-input'
            />
          </div>
          <div className="grey-line"></div>

          <div className='tax-note'>! Az árak alanyi adómentesek !</div>
        </div>

        {/* Gradient Divider */}
        <div className='rust-divider'></div>

        {/* Summary Section */}
        <div className='summary-section'>
          <h1>Összegzés</h1>
          <div className="grey-line"></div>
          <div className='summary-content'>
            <p className='white-text'>Memória <span className='sublabel'>- {ram}GB</span></p>
            <p className='white-text'>Tárhely <span className='sublabel'>- {storage}GB</span></p>
            <p className='white-text'>Processzor szálak <span className='sublabel'>- {cpu}</span></p>
            <p className='white-text'>Adatbázisok <span className='sublabel'>- 4</span></p>
            <div className="grey-line"></div>
            <input
              type="text"
              placeholder="Kupon"
              value={couponCode}
              onChange={(e) => setCouponCode(e.target.value)}
              className='coupon-input'
            />
            <div className="grey-line"></div>
            <p className='total-cost'>Összesen: <span className='boldlabel'>{calculateCost()} Ft</span> <span className='sublabel'> / Hó</span></p>
            <span className='sublabel'>Lejárat - {expirationDate}</span>
            <div className="grey-line"></div>
            <button onClick={handleBuy} className='rent-button'>Szerver bérlése</button>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default Rust;
