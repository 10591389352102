import React from 'react';
import Navbar from '../../components/Navbar/Navbar.jsx';
import Footer from '../../components/Footer/Footer.jsx';
import './landingp.scss';
import { useNavigate } from 'react-router-dom';

const Landing = () => {

    const scrollToTop = () => {
        window.scrollTo(0, 0);
    };

    const onClickaskus = () => {
        window.open('https://dc.hexaverse.hu', '_blank');
    };

    const onClickPanel = () => {
        window.open('https://panel.hexaverse.hu', '_blank');
    };

    const navigate = useNavigate();

    const onClickServices = () => {
        navigate("/szolgaltatasaim");
        scrollToTop();
    };

    const onClickBalance = () => {
        navigate("/egyenlegem");
        scrollToTop();
    };

    return (
        <div>
            <Navbar />
            <h1 className='welcoming__message'>Üdv újra {localStorage.getItem('username')}!</h1>
            <div className="landing__grid">
                <div className="landing__box landing__box--1">
                    <h1>Egyenlegem & Kuponjaim</h1>
                    <p>Tölts fel, és kezeld egyenleged kedvedre!</p>
                    <button onClick={onClickBalance}>Kezelés</button>
                </div>
                <div className="landing__box landing__box--2">
                    <h1>Szolgáltatásaim</h1>
                    <p>Itt láthatod és kezelheted a szolgáltatásaidat!</p>
                    <button onClick={onClickServices}>Kezelés</button>
                </div>
                <div className="landing__box landing__box--3">
                    <h1>Game Panel</h1>
                    <p>Kezeld játékszervereid egy helyen.</p>
                    <button onClick={onClickPanel}>Megnyitás</button>
                </div>
                <div className="landing__box landing__box--4">
                    <h1>Gyors segítségkérés</h1>
                    <p>Nyiss egy hibajegyet és csapatunk segítségedre siet.</p>
                    <button onClick={onClickaskus}>Discord</button>
                </div>
            </div>
            <Footer />
        </div>
    );
};

export default Landing;