function buyMinecraft(type, name, memory, disk, cpu, serverVersion, couponid) {
    return new Promise((resolve, reject) => {
        if (!couponid) {
            couponid = null;
        }
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization", `Bearer ${localStorage.getItem("F49w'2jTP:mE6C/fJQXA,G")}`);

        const raw = JSON.stringify({
            "service_type": type,
            "serverdata": {
                "serverName": name,
                "memory": memory,
                "disk": disk,
                "cpu": cpu,
                "version": serverVersion,
            },
            "couponid": couponid
        });

        const requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow"
        };

        fetch("https://api.hexaverse.hu/api/v1/services/buy", requestOptions)
            .then(async (response) => {
                const responseJson = await response.json();
                console.log(responseJson)
                if (responseJson.message === "success") {
                    resolve(true);
                } else if (responseJson.error === "error") {
                    resolve(false);
                } else if (responseJson.message === "nómáni"){
                    resolve(911);
                } else if (responseJson.message === "Invalid Token"){
                    resolve(69);
                }
            })
            .catch((error) => reject(error));
    });
}

function buyService(type, name, memory, disk, cpu, couponid) {
    return new Promise((resolve, reject) => {
        if (!couponid) {
            couponid = null;
        }
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization", `Bearer ${localStorage.getItem("F49w'2jTP:mE6C/fJQXA,G")}`);

        const raw = JSON.stringify({
            "service_type": type,
            "serverdata": {
                "serverName": name,
                "memory": memory,
                "disk": disk,
                "cpu": cpu,
            },
            "couponid": couponid
        });

        const requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow"
        };

        fetch("https://api.hexaverse.hu/api/v1/services/buy", requestOptions)
            .then(async (response) => {
                const responseJson = await response.json();
                if (responseJson.message === "success") {
                    resolve(true);
                } else if (responseJson.error === "error") {
                    resolve(false);
                } else if (responseJson.message === "nómáni"){
                    resolve(911);
                } else if (responseJson.message === "Invalid Token"){
                    resolve(69);
                }
            })
            .catch((error) => reject(error));
    });
}

module.exports = {
    buyMinecraft,
    buyService
};
