import React, { useEffect, useState } from "react";
import './RegPage.scss';
import hxlogo from '../../assets/images/hxlogo.png';
import { useNavigate } from 'react-router-dom'

const ForgotPassword = () => {
  const [formData, setFormData] = useState({
    email: '',
    newpassword: '',
    confirmPassword: '',
    code: ''
  });

  const [error, setError] = useState('');
  const [stage, setStage] = useState(1); // A folyamat szakaszának követése
  const [seconds, setSeconds] = useState(5);
  const [successReg, setSuccessReg] = useState(false);
    useEffect(() => {
      if (seconds <= 0) {
          onSuccessReset();
      }
  }, [seconds]);

  const navigate = useNavigate();
  const onSuccessReset = () => {
      navigate("/ugyefelkapu");
  }
  const startTimer = () => {
    setSuccessReg(true);
    let interval;

    interval = setInterval(() => {
        setSeconds((prevSeconds) => prevSeconds - 1);
    }, 1000);
};

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleNextStage = () => {
    if (stage === 1) {
      onForgotPassword()
      setError('');
    }
    setStage((prevStage) => prevStage + 1);
  };

  const handlePreviousStage = () => {
    setError('');
    setStage((prevStage) => prevStage - 1);
  };

  const handlePasswordReset = (e) => {
    e.preventDefault();
    if (formData.newpassword.length < 8) {
      setError('Az új jelszónak legalább 8 karakter hosszúnak kell lennie.');
      return;
    } else if (formData.newpassword !== formData.confirmPassword) {
      setError('A két új jelszó nem egyezik meg.');
      return;
    } else {
      setError('');
      onForgotPassword();
    }
  };

  const onForgotPassword = () => {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    
    const raw = JSON.stringify({
      "email": formData.email
    });
    
    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow"
    };
    
    fetch("https://api.hexaverse.hu/api/v1/auth/forgotpass", requestOptions)
      .then((response) => response.text())
      .then((result) => console.log(result))
      .catch((error) => console.error(error));
  };

  const onForgotPasswordNext = () => {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    
    const raw = JSON.stringify({
      "email": formData.email,
      "forgotCode": formData.code,
      "newpassword": formData.newpassword,
      "newpasswordagain": formData.confirmPassword
    });
    
    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow"
    };
    
    fetch("https://api.hexaverse.hu/api/v1/auth/forgotpass/complete", requestOptions)
      .then(async (response) => {
        const responseJson = await response.json()
        console.log(response)
        console.log(responseJson)
        if(responseJson.message === "success"){
          startTimer()
        }
      })
      .then((result) => console.log(result))
      .catch((error) => console.error(error));
  };

  return (
    <div className="reg-container">
      <div className="logo-section">
        <img src={"https://cdn.hexaverse.hu/hxlogo.png"} alt="HexaVerse Logo" className="logo" />
      </div>
      <div className="form-divider"></div>
      <div className={`reg-box stage-${stage}`}>
        <h1 className="reg-title">Jelszó visszaállítás</h1>

        <div className="reg-form">
          {stage === 1 && (
            <div className="stage active">
              <div className="input-group">
                <label>Email</label>
                <input
                  type="email"
                  name="email"
                  placeholder="Email"
                  value={formData.email}
                  onChange={handleChange}
                  required
                />
              </div>
              <button type="button" className="continue-button" onClick={handleNextStage}>
                Tovább
              </button>
            </div>
          )}

          {stage === 2 && (
            <div className="stage active">
              <div className="input-group">
                <label>Code</label>
                <input
                  type="code"
                  name="code"
                  placeholder="4 jegyű kód"
                  value={formData.code}
                  onChange={handleChange}
                  required
                />
              </div>
              <button type="button" className="continue-button" onClick={handleNextStage}>
                Tovább
              </button>
            </div>
          )}

          {stage === 3 && (
            <div className="stage active">
              <div className="input-group">
                <label>Új jelszó</label>
                <input
                  type="password"
                  name="newpassword"
                  placeholder="Új jelszó"
                  value={formData.newpassword}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="input-group">
                <label>Új jelszó újra</label>
                <input
                  type="password"
                  name="confirmPassword"
                  placeholder="Új jelszó újra"
                  value={formData.confirmPassword}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="button-group">
                <button type="button" className="back-button" onClick={handlePreviousStage}>
                  Vissza
                </button>
                <button type="submit" className="reg-button" onClick={onForgotPasswordNext}>
                  Jelszócsere
                </button>
              </div>
            </div>
          )}
        </div>

              {successReg && (
                <div>
                  <RegSuccess message={`Sikeresen regisztráció. Átirányítás: ${seconds}`} />
                </div>
              )}

        <p className="login-link">
          Már van fiókod? <a href="/auth/login">Jelentkezz be!</a>
        </p>
      </div>
    </div>
  );
};

// Hibaüzenet komponens
function ErrorBox({ errors }) {
  return (
    <div className='errorbox'>
      <p>{errors}</p>
    </div>
  );
}

function RegSuccess({ message }) {
  return (
    <div className='regsuccess'>
      <p>{message}</p>
    </div>
  );
}

export default ForgotPassword;
