import React from 'react';
import './Verziok.scss';
import Navbar from '../../components/Navbar/Navbar.jsx';
import Footer from '../../components/Footer/Footer.jsx';

const Verziok = () => {
  return (
    <div>
      <Navbar />
      <div className="versions-container">
        <div className="version-two">
          <h2>Vanilla</h2>
          <p>Módosítatlan játék. Gyűjts erőforrásokat, éld túl a szörnyeket, egy nyitott világban és használd végtelen kreativitásod.</p>
        </div>
        <div className="version-one">
          <h2>Spigot</h2>
          <p>Jobb teljesítményt biztosít. Támogatja a pluginokat. Biztonságosabb azoknak, akik kifagyás nélkül szeretnének barátaikkal, vagy játékosaikkal egy időben játszani.</p>
        </div>
        <div className="version-two">
          <h2>Paper</h2>
          <p>A Spigot továbbfejlesztett verziója, amely jobban kíméli szervered, ezek mellett pedig rengeteg hibát javít ki a még optimálisabb teljesítményért.</p>
        </div>
        <div className="version-two">
          <h2>Purpur</h2>
          <p>Egy rendkívül testreszabható szerver verzió, amely optimalizálja a teljesítményt és számos új funkciót kínál, hogy javítsa a játékélményt és kezelhetőséget.</p>
        </div>
        <div className="version-one">
          <h2>Fabric</h2>
          <p>Egy stabil modolt élményt nyújt. Az újabb verzióknak köszönhetően stabilabb és frissebb teljesítmény növelő kiegészítőket találhatsz játékosaid számára.</p>
        </div>
        <div className="version-two">
          <h2>Bungeecord</h2>
          <p>Több szervered összeköttetését biztosítja, a legegyszerűbb megoldás arra a problémára, hogyha szeretnétek egy szerver hálózatot üzemeltetni.</p>
        </div>
        <div className="version-one">
          <h2>Velocity</h2>
          <p>Egy gyors és megbízható proxy szerver, amely optimalizálja a kapcsolatot és csökkenti a késleltetést, tökéletes választás nagy szerverhálózatokhoz és cross-server kommunikációhoz.</p>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Verziok;
