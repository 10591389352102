import React from 'react';
import Navbar from '../../components/Navbar/Navbar.jsx';
import Footer from '../../components/Footer/Footer.jsx';
import './Gsbase.scss';

const VPS = () => {
  return (
    <div>
        <Navbar />
        <h1 className='sigmabox'>VPS Bérlés jelenleg nem lehetséges a weboldalon.</h1>
        <Footer />
    </div>
  )
}

export default VPS;