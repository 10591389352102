import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { setUserData, getUserData } from "../../data/DataFunctions";
import { useNavigate } from 'react-router-dom'

const PaypalPage = () => {
    const [sessionId, setSessionId] = useState(null);
    const [successReg, setSuccessReg] = useState(false);
    const location = useLocation();

    //   // TIMER
    const [seconds, setSeconds] = useState(5);

    useEffect(() => {
        if (seconds <= 0) {
            onSuccessBuy();
        }
    }, [seconds]);

    const navigate = useNavigate();
    const onSuccessBuy = () => {
        navigate("/szolgaltatasaim");
    }

    const startTimer = () => {
        setSuccessReg(true);
        let interval;

        interval = setInterval(() => {
            setSeconds((prevSeconds) => prevSeconds - 1);
        }, 1000);
    };
    // TIMER VÉGE

    let tempAsd = false;
    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const sessionIdParam = queryParams.get("token");
        setSessionId(sessionIdParam);

        if(!tempAsd) {
            tempAsd = true;
            const myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");
            myHeaders.append("Authorization", `Bearer ${localStorage.getItem("F49w'2jTP:mE6C/fJQXA,G")}`);

            const raw = JSON.stringify({
                "orderid": sessionIdParam
            });

            const requestOptions = {
                method: "POST",
                headers: myHeaders,
                body: raw,
                redirect: "follow"
            };

            fetch("https://api.hexaverse.hu/api/v1/payment/paypal/complete", requestOptions)
                .then(async (response) => {
                    const responseJson = await response.json();
                    if(responseJson.coinAmount) {
                        setUserData({coin: responseJson.coinAmount});
                    }
                    startTimer();
                })
                .catch((error) => console.error(error));
        }
    }, [location.search]);

    return (
        <div>
            <p style={{color: 'green'}}>Sikeres vásárlás. Átirányítás {seconds} másodpercen belül.</p>
        </div>
    )
}


export default PaypalPage;