import React, { useState, useCallback, useEffect } from 'react';
import './Gsbase.scss';
import Navbar from '../../components/Navbar/Navbar.jsx';
import Footer from '../../components/Footer/Footer.jsx';
import mc_banner from '../../assets/images/mc_banner.png';
import { debounce } from "lodash";


const { buyMinecraft } = require("../../payment/paymentFunctions.js")

// Define the available versions for each server type
const versions = {
  vanilla: [
    "1.21", "1.20.6", "1.20.5", "1.20.4", "1.20.3", "1.20.2", "1.20.1",
    "1.20", "1.19.4", "1.19.3", "1.19.2", "1.19.1", "1.19", "1.18.2",
    "1.18.1", "1.18", "1.17.1", "1.17", "1.16.5", "1.16.4", "1.16.3",
    "1.16.2", "1.16.1", "1.16", "1.15.2", "1.15.1", "1.15", "1.14.4",
    "1.14.3", "1.14.2", "1.14.1", "1.14", "1.13.2", "1.13.1", "1.13",
    "1.12.2", "1.12.1", "1.12", "1.11.2", "1.11.1", "1.11", "1.10.2",
    "1.10.1", "1.10", "1.9.4", "1.9.3", "1.9.2", "1.9.1", "1.9",
    "1.8.9", "1.8.8"
  ],
  spigot: [
    "1.21", "1.20.6", "1.20.5", "1.20.4", "1.20.3", "1.20.2", "1.20.1",
    "1.20", "1.19.4", "1.19.3", "1.19.2", "1.19.1", "1.19", "1.18.2",
    "1.18.1", "1.18", "1.17.1", "1.17", "1.16.5", "1.16.4", "1.16.3",
    "1.16.2", "1.16.1", "1.16", "1.15.2", "1.15.1", "1.15", "1.14.4",
    "1.14.3", "1.14.2", "1.14.1", "1.14", "1.13.2", "1.13.1", "1.13",
    "1.12.2", "1.12.1", "1.12", "1.11.2", "1.11.1", "1.11", "1.10.2",
    "1.10.1", "1.10", "1.9.4", "1.9.3", "1.9.2", "1.9.1", "1.9",
    "1.8.9", "1.8.8"
],
paper: [
    "1.21", "1.20.6", "1.20.5", "1.20.4", "1.20.3", "1.20.2", "1.20.1",
    "1.20", "1.19.4", "1.19.3", "1.19.2", "1.19.1", "1.19", "1.18.2",
    "1.18.1", "1.18", "1.17.1", "1.17", "1.16.5", "1.16.4", "1.16.3",
    "1.16.2", "1.16.1", "1.16", "1.15.2", "1.15.1", "1.15", "1.14.4",
    "1.14.3", "1.14.2", "1.14.1", "1.14", "1.13.2", "1.13.1", "1.13",
    "1.12.2", "1.12.1", "1.12", "1.11.2", "1.11.1", "1.11", "1.10.2",
    "1.10.1", "1.10", "1.9.4", "1.9.3", "1.9.2", "1.9.1", "1.9",
    "1.8.9", "1.8.8"
],
fabric: [
    "1.21", "1.20.6", "1.20.5", "1.20.4", "1.20.3", "1.20.2", "1.20.1",
    "1.20", "1.19.4", "1.19.3", "1.19.2", "1.19.1", "1.19", "1.18.2",
    "1.18.1", "1.18", "1.17.1", "1.17", "1.16.5", "1.16.4", "1.16.3",
    "1.16.2", "1.16.1", "1.16"
],
bungeecord: [
    "1.21", "1.20.6", "1.20.5", "1.20.4", "1.20.3", "1.20.2", "1.20.1",
    "1.20", "1.19.4", "1.19.3", "1.19.2", "1.19.1", "1.19", "1.18.2",
    "1.18.1", "1.18", "1.17.1", "1.17", "1.16.5", "1.16.4", "1.16.3",
    "1.16.2", "1.16.1", "1.16"
],
velocity: [
    "1.21", "1.20.6", "1.20.5", "1.20.4", "1.20.3", "1.20.2", "1.20.1",
    "1.20", "1.19.4", "1.19.3", "1.19.2", "1.19.1", "1.19", "1.18.2",
    "1.18.1", "1.18", "1.17.1", "1.17", "1.16.5", "1.16.4", "1.16.3",
    "1.16.2", "1.16.1", "1.16"
],  
};

const Minecraft = () => {
  const [errorMessage, seterrorMessage] = useState('');
  const [fadeOut, setFadeOut] = useState(true); // Kezdetben el van rejtve
  const [ram, setRam] = useState(0.5);
  const [storage, setStorage] = useState(15);
  const [cpu, setCpu] = useState(1.5);
  const [serverType, setServerType] = useState('vanilla');
  const [serverVersion, setServerVersion] = useState(versions.vanilla[0]); // Default to the first version of Vanilla
  const [serverName, setServerName] = useState('');
  const [couponCode, setCouponCode] = useState('');
  const [isTypeOpen, setIsTypeOpen] = useState(false);
  const [isVersionOpen, setIsVersionOpen] = useState(false);

  const calculateCost = () => {
    const ramCost = ram >= 9 ? ram * 450 : ram * 500;
    const storageCost = storage > 15 ? (storage - 15) * 25 : 0;
    const cpuCost = cpu <= 1.5 ? 0 : Math.ceil((cpu - 1.5) * 2) * 250;
    return ramCost + storageCost + cpuCost;
  };

  const validateCoupon = (couponId) => {
    const myHeaders = new Headers();
    console.log(couponId)
    myHeaders.append("Content-Type", "application/json");

    const raw = JSON.stringify({
      couponid: couponId
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow"
    };

    fetch("https://api.hexaverse.hu/api/v1/payment/coupon/validate", requestOptions)
      .then(async (response) => {
        const responseJson = await response.json()
        console.log(responseJson)
        if (!responseJson.percent) {
          console.log("Szar a kupon")
        } else {
          console.log("Kupon százalék: " + responseJson.percent)
        }
      })
      .catch((error) => console.error(error));
  }
  const handleDebouncedChange = useCallback(
    debounce((value) =>{
      validateCoupon(value)
    }, 500),
    []
  )
  const handleCouponIdChange = (e) => {
    const value = e.target.value;
    setCouponCode(value)
    handleDebouncedChange(value)
  }
  const handleTypeSelection = (type) => {
    setServerType(type);
    setServerVersion(versions[type][0]); // Set default version for the selected type
    setIsTypeOpen(false);
  };

  const handleVersionSelection = (version) => {
    setServerVersion(version);
    setIsVersionOpen(false);
  };
  const [messageType, setMessageType] = useState('');

  const sendConfigToBackend = async () => {
    const type = `minecraft_` + serverType;

    const isLoggedIn = localStorage.getItem("F49w'2jTP:mE6C/fJQXA,G");
    if (!isLoggedIn || isLoggedIn === undefined || isLoggedIn === null) {
      seterrorMessage("Vásárlás előtt be kell jelentkezned.");
      setMessageType("error");
      setFadeOut(false);
      setTimeout(() => setFadeOut(true), 4000);
      return;
    }
  
    if (!serverName.trim()) {
      seterrorMessage("Kérlek adj meg egy szerver nevet.");
      setMessageType("error");
      setFadeOut(false);
      setTimeout(() => setFadeOut(true), 4000);
      return;
    }
  
    const status = await buyMinecraft(type, serverName, ram * 1024, storage * 1024, cpu * 100, serverVersion, couponCode);
    console.log(status + " fes")
    if (status === true) {
      seterrorMessage("A vásárlás sikeresen megtörtént!");
      setMessageType("success");
      setFadeOut(false);
      setTimeout(() => setFadeOut(true), 4000);
    } else if (status === 69) {
      seterrorMessage("Jelentkezz be büdös buzi.");
      setMessageType("error");
      setFadeOut(false);
      setTimeout(() => setFadeOut(true), 4000);
      return;
    } else if (status === 911) {
      seterrorMessage("NÓ LENNI NEKED ELÉG MÁNI, PLÍZ VENNIMÁNi FÖRSZT.");
      setMessageType("error");
      setFadeOut(false);
      setTimeout(() => setFadeOut(true), 4000);
      return;
    } else {
      seterrorMessage("Hiba történt a vásárlás során. Kérjük, próbáld újra.");
      setMessageType("error");
      setFadeOut(false);
      setTimeout(() => setFadeOut(true), 4000);
    }
  };
  
  const [expirationDate, setExpirationDate] = useState('');
  useEffect(() => {
    const currentDate = new Date();
    currentDate.setDate(currentDate.getDate() + 30);
    setExpirationDate(currentDate.toLocaleDateString('hu-HU'));
  }, []);

  return (
    <div>
      <div className={`green-error-box ${fadeOut ? 'fade-out' : ''}`}>
        <span className="error-message">{errorMessage}</span>
      </div>
      <Navbar />
      <div className='gs__container'>
        <img className='gs__banner' src={mc_banner} alt="kep1" />
      </div>

      <div className='config-summary-container'>
        {/* Server Configuration Section */}
        <div className='configuration-section'>
          <h1>Szerver konfiguráció</h1>

          <div className="slider-wrapper">
            <label className='white-text'>Memória <span className='sublabel'>- {ram}GB</span></label>
            <div className='slider-container'>
              <input
                type="range"
                min="0.5"
                max="32"
                step="0.5"
                value={ram}
                onChange={(e) => setRam(parseFloat(e.target.value))} // Use parseFloat instead of parseInt
                className='slider minecraft-slider_a'
              />
            </div>
            <div className='slider-container-buttons'>
              <button onClick={() => setRam(Math.max(0.5, ram - 0.5))}>-</button>
              <button onClick={() => setRam(Math.min(32, ram + 0.5))}>+</button>
            </div>
          </div>

          {/* Storage Slider */}
          <div className="slider-wrapper">
            <label className='white-text'>Tárhely <span className='sublabel'>- {storage}GB</span></label>
            <div className='slider-container'>
              <input
                type="range"
                min="15"
                max="100"
                value={storage}
                onChange={(e) => setStorage(parseInt(e.target.value))}
                className='slider minecraft-slider_b'
              />
            </div>
            <div className='slider-container-buttons'>
              <button onClick={() => setStorage(Math.max(10, storage - 1))}>-</button>
              <button onClick={() => setStorage(Math.min(100, storage + 1))}>+</button>
            </div>
          </div>

          {/* CPU Slider */}
          <div className="slider-wrapper">
            <label className='white-text'>Processzor szálak <span className='sublabel'>- {cpu}</span></label>
            <div className='slider-container'>
              <input
                type="range"
                min="1.5"
                max="8"
                step="0.5"
                value={cpu}
                onChange={(e) => setCpu(parseFloat(e.target.value))}
                className='slider minecraft-slider_c'
              />
            </div>
            <div className='slider-container-buttons'>
              <button onClick={() => setCpu(Math.max(1.5, cpu - 0.5))}>-</button>
              <button onClick={() => setCpu(Math.min(8, cpu + 0.5))}>+</button>
            </div>
          </div>

          <div className="grey-line"></div>

          {/* Dropdowns */}
          <div className='details'>
            <label>Részletek</label>

            <div className="dropdown-container">
              <button onClick={() => setIsTypeOpen(!isTypeOpen)} className="dropdown-button">
                {serverType} <span className="dropdown-icon">{isTypeOpen ? '−' : '+'}</span>
              </button>
              {isTypeOpen && (
                <div className="dropdown-menu open">
                  {Object.keys(versions).map((type) => (
                    <div key={type} onClick={() => handleTypeSelection(type)}>{type}</div>
                  ))}
                </div>
              )}
            </div>

            <div className="dropdown-container">
              <button onClick={() => setIsVersionOpen(!isVersionOpen)} className="dropdown-button">
                {serverVersion} <span className="dropdown-icon">{isVersionOpen ? '−' : '+'}</span>
              </button>
              {isVersionOpen && (
                <div className="dropdown-menu open">
                  {versions[serverType].map((version) => (
                    <div key={version} onClick={() => handleVersionSelection(version)}>{version}</div>
                  ))}
                </div>
              )}
            </div>

            <input
              type="text"
              placeholder="Szerver neve"
              value={serverName}
              onChange={(e) => setServerName(e.target.value)}
              className='server-name-input'
              required
            />
          </div>

          <div className="grey-line"></div>
          <div className='tax-note'>! Az árak alanyi adómentesek !</div>
        </div>

        {/* Summary Section */}
        <div className='mc-divider'></div>
        <div className='summary-section'>
          <h1>Összegzés</h1>
          <div className="grey-line"></div>
          <div className='summary-content'>
            <p className='white-text'>Memória <span className='sublabel'>- {ram}GB</span></p>
            <p className='white-text'>Tárhely <span className='sublabel'>- {storage}GB</span></p>
            <p className='white-text'>Processzor szálak <span className='sublabel'>- {cpu}</span></p>
            <p className='white-text'>Adatbázisok <span className='sublabel'>- 4</span></p>
            <div className="grey-line"></div>
            <input
              type="text"
              placeholder="Kupon"
              value={couponCode}
              onChange={handleCouponIdChange}
              className='coupon-input'
            />
            <div className="grey-line"></div>
            <p className='total-cost'>Összesen: <span className='boldlabel'>{calculateCost()} Ft</span> <span className='sublabel'> / Hó</span></p>
        
            <span className='sublabel'>Lejárat - {expirationDate}</span>
            <div className="grey-line"></div>
            <button className='rent-button' onClick={sendConfigToBackend}>Szerver bérlése</button>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default Minecraft;
