import React from 'react'
import bg1 from '../../assets/images/mcnew.png'
import './bg1.scss'

const Bg1 = () => {
  return (
    <div>
        <div className="bg1">
        </div>
    </div>
  )
}

export default Bg1