import React, { useState } from 'react';

const Coupon = () => {
  const [couponPercent, setCouponPercent] = useState(0);
  const [couponId, setCouponID] = useState('');

  const handleClick = () => {
    const token = localStorage.getItem("F49w'2jTP:mE6C/fJQXA,G");

    if (!token) {
      console.error("No token found in localStorage");
      return;
    }

    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${token}`);

    console.log('Coupon Percent:', couponPercent, 'Coupon ID:', couponId);

    const raw = JSON.stringify({
      percent: couponPercent,
      couponid: couponId,
    });

    const requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow',
    };

    fetch('https://api.hexaverse.hu/api/v1/admin/coupon/create', requestOptions)
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        return response.json();
      })
      .then((result) => console.log('Success:', result))
      .catch((error) => console.error('Error:', error));
  };

  return (
    <div>
      <input
        type="number"
        value={couponPercent}
        onChange={(e) => setCouponPercent(Number(e.target.value))}
        placeholder="Coupon Percent"
      />
      <input
        type="text"
        value={couponId}
        onChange={(e) => setCouponID(e.target.value)}
        placeholder="Coupon ID"
      />
      <button onClick={handleClick}>Create Coupon</button>
    </div>
  );
};

export default Coupon;
