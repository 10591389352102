import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import './renew.scss';
import Navbar from '../../components/Navbar/Navbar.jsx';
import Footer from '../../components/Footer/Footer.jsx';

const Serverrenew = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { state } = location;

  const [ram, setRam] = useState((localStorage.getItem(`${state?.uuid}/ram`) / 1024) );
  const [storage, setStorage] = useState((localStorage.getItem(`${state?.uuid}/disk`) / 1024));
  const [cpu, setCpu] = useState((localStorage.getItem(`${state?.uuid}/cpu`) / 100));
  const [serverType, setServerType] = useState('Vanilla'); // Ez változhat az igények szerint
  const [serverName, setServerName] = useState(state?.service_name || '');
  const [autoRenew, setAutoRenew] = useState(false);
  const [isTypeOpen, setIsTypeOpen] = useState(false);

  const date = new Date(parseInt(localStorage.getItem(`${state?.uuid}/expires`), 10))

  useEffect(() => {
    if (!state) {
      navigate('/'); // Ha nincs adat, visszairányítjuk a kezdőlapra
    }
  }, [state, navigate]);

  const calculateCost = () => {
    const ramCost = ram >= 9 ? ram * 450 : ram * 500;
    const storageCost = storage > 15 ? (storage - 15) * 25 : 0;
    const cpuCost = cpu <= 1.5 ? 0 : Math.ceil((cpu - 1.5) * 2) * 250;
    return ramCost + storageCost + cpuCost;
  };

  const toggleTypeDropdown = () => setIsTypeOpen(!isTypeOpen);
  const handleTypeSelection = (type) => {
    setServerType(type);
    setIsTypeOpen(false);
  };

  const handleSave1 = () => {
    // Szerver konfiguráció mentése (később itt lehet API-hívás vagy state-frissítés)
    console.log('Szerver konfiguráció mentve:', {
      serverName,
      ram,
      storage,
      cpu,
      serverType,
      autoRenew,
    });
    const token = localStorage.getItem("F49w'2jTP:mE6C/fJQXA,G");
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${token}`);

    const raw = JSON.stringify({
      service_id: state?.uuid, 
      memory: ram * 1024,
      cpu: cpu * 100,
      disk: storage * 1024
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow"
    };

    fetch("https://api.hexaverse.hu/api/v1/services/update", requestOptions)
      .then(async (response) => {
        console.log(response)
      }).catch((error) => console.error(error));
    alert('Szerver adatai frissítve!');
  };

  return (
    <div>
      <Navbar />
      <div className='config-summary-container'>
        <div className='configuration-section'>
          <h1>Adatok módosítása</h1>
          <div className='server-info'>
            <p><strong>Szerver neve:</strong> {serverName}</p>
            <p><strong>Szerver UUID:</strong> {state?.uuid}</p>
          </div>
          <div className="grey-line"></div>
          <div className="slider-wrapper">
            <label className='white-text'>
              Memória <span className='sublabel'>- {ram}GB</span>
            </label>
            <div className='slider-container'>
              <input
                type="range"
                min="0.5"
                max="32"
                step="0.5"
                value={ram}
                onChange={(e) => setRam(parseInt(e.target.value))}
                className='slider garrysmod-slider_a'
              />
            </div>
            <div className='slider-container-buttons'>
              <button onClick={() => setRam(Math.max(0.5, ram - 1))}>-</button>
              <button onClick={() => setRam(Math.min(32, ram + 1))}>+</button>
            </div>
          </div>

          <div className="slider-wrapper">
            <label className='white-text'>
              Tárhely <span className='sublabel'>- {storage}GB</span>
            </label>
            <div className='slider-container'>
              <input
                type="range"
                min="15"
                max="100"
                value={storage}
                onChange={(e) => setStorage(parseInt(e.target.value))}
                className='slider garrysmod-slider_b'
              />
            </div>
            <div className='slider-container-buttons'>
              <button onClick={() => setStorage(Math.max(15, storage - 1))}>-</button>
              <button onClick={() => setStorage(Math.min(100, storage + 1))}>+</button>
            </div>
          </div>

          <div className="slider-wrapper">
            <label className='white-text'>
              Processzor szálak <span className='sublabel'>- {cpu}</span>
            </label>
            <div className='slider-container'>
              <input
                type="range"
                min="0.5"
                max="8"
                step="0.5"
                value={cpu}
                onChange={(e) => setCpu(parseFloat(e.target.value))}
                className='slider garrysmod-slider_c'
              />
            </div>
            <div className='slider-container-buttons'>
              <button onClick={() => setCpu(Math.max(1.5, cpu - 0.5))}>-</button>
              <button onClick={() => setCpu(Math.min(8, cpu + 0.5))}>+</button>
            </div>
          </div>
          <div className="grey-line"></div>
          <label className='checkbox-container'>
            Automatikus hosszabbítás
            <input
              type="checkbox"
              checked={autoRenew}
              onChange={(e) => setAutoRenew(e.target.checked)}
              className='auto-renew-checkbox'
            />
            <span className="custom-checkbox"></span>
          </label>
        </div>

        <div className='garrysmod-divider'></div>

        <div className='summary-section'>
          <h1>Összegzés</h1>
          <div className="grey-line"></div>
          <div className='summary-content'>
            <p className='white-text'>Memória <span className='sublabel'>- {ram}GB</span></p>
            <p className='white-text'>Tárhely <span className='sublabel'>- {storage}GB</span></p>
            <p className='white-text'>Processzor szálak <span className='sublabel'>- {cpu}</span></p>
            <p className='white-text'>Adatbázisok <span className='sublabel'>- 4</span></p>
            <div className="grey-line"></div>
            <p className='total-cost'>Összesen: <span className='boldlabel'>{calculateCost()} Ft</span> <span className='sublabel'> / Hó</span></p>
            <span className='sublabel-specificc'>Lejárat - <span>{date.getFullYear()}.</span><span>{date.getMonth() < 10 ? (`0${(date.getMonth() + 1)}`) : date.getMonth() + 1}.</span><span>{date.getDate() < 10 ? (`0${date.getDate()}`) : date.getDate()}.</span></span>
            <button className='rent-button' onClick={handleSave1}>Mentés</button>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default Serverrenew;
