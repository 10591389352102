import React, { useEffect, useState } from 'react';
import Navbar from '../../components/Navbar/Navbar.jsx';
import Footer from '../../components/Footer/Footer.jsx';
import serverImage from '../../assets/images/cpuryzen9.png';
import '../aboutus/about.scss';

const About = () => {
  const [imageLoaded, setImageLoaded] = useState(false);

  useEffect(() => {
    const disableSelection = (e) => e.preventDefault();
    document.addEventListener('selectstart', disableSelection);
    return () => {
      document.removeEventListener('selectstart', disableSelection);
    };
  }, []);

  return (
    <div>
      <Navbar />
      <div className="about-container">
        {/* Rólunk doboz */}
        <div className="box">
          <h1 className="container__cim">Rólunk</h1>
          <p className="about-text">
            A <span className="highlight"> HexaVerse</span> 2024 Szeptember elsején <span className="highlight">sikeresen megnyitott</span>. 
            Csapatunk mögött több, mint <span className="highlight">4 év tapasztalat</span> áll, és arra törekszünk, hogy a lehető 
            <span className="highlight"> legkedvezőbb élményt</span> nyújtsuk vásárlóinknak mindenben. Csapatunk játék szerverek, VPS-ek 
            és egyéb virtuális szolgáltatások üzemeltetésén kívül foglalkozik még: 
            <span className="highlight">Webes alkalmazások fejlesztésével</span>, és különböző programnyelvekkel is. 
            Áraink pedig <span className="highlight">nem tartalmaznak semmilyen rejtett költséget</span>.
          </p>
        </div>

        {/* Szervergép adatai doboz */}
        <div className="box">
          <h1 className="container__cim">Szervergép adatai</h1>
          <p className="about-text">
            Jelenleg <span className="highlight">3 szervergépünk</span> van, ezek közül <span className="highlight">2 van kiadva állandóan</span>. 
            Játékszervereink gépében egy <span className="highlight">Ryzen 9 7900</span>-as processzor található, 
            míg VPS szolgáltatásainkat egy <span className="highlight">Ryzen 9 7950X</span> hajtja az <span className="highlight">ATW-nél</span>.
          </p>
          <img 
            src={serverImage} 
            alt="Szervergép képe" 
            className={`server-image ${imageLoaded ? 'image-loaded' : ''}`} 
            onLoad={() => setImageLoaded(true)} /* Animáció aktiválása a betöltéskor */
          />
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default About;
