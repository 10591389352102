import React, {useEffect} from 'react';
import Navbar from '../../components/Navbar/Navbar.jsx';
import Footer from '../../components/Footer/Footer.jsx';
import './egyenlegeskuponok.scss';
import { useNavigate } from 'react-router-dom'


const Egyenlegem = () => {

  const navigate = useNavigate();
  const onClickEgyenlegfeltoltesone = () => {
      navigate("/egyenlegfeltoltes")
  }
  function getUserCoins(token) {
    if (!token) {
      return console.error("Token is not defined!")
    }
    
  }
  const counter = 0;
  useEffect(() => {
    if(counter > 0) return
    const coinSpan = document.getElementById("coin")
		const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${localStorage.getItem("F49w'2jTP:mE6C/fJQXA,G")}`);

    const raw = "";

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow"
    };

    fetch("https://api.hexaverse.hu/api/v1/auth/user/get", requestOptions)
      .then(async(response) => {
        try {
            const responseJson = await response.json()
            const coinInLocalStorage = localStorage.getItem("coin")
            if(coinInLocalStorage) {
              localStorage.removeItem("coin")
              localStorage.setItem("coin", responseJson.coin)
              coinSpan.innerHTML = responseJson.coin
            } else {
              localStorage.setItem("coin", responseJson.coin)
              coinSpan.innerHTML = responseJson.coin
            }   
        } catch (error) {
            console.log(error)
        }
      })
      .catch((error) => console.error(error));

    
	}, []);

  return (
    <div>
      <Navbar />
      <div className="balance__container">
        <h1>Vásárlási előzmények</h1>


        <div className="payment__out__container">
          <p className="service__type">Itt tekintheted meg feltöltéseid és kiadásaid. - Hamarosan!</p>
          <p className='price'></p>
        </div>

{/*        <div className="payment__out__container">
          <p className="service__type">MTA szerver</p>
          <p className='price'>- 2000Ft</p>
        </div>

        <div className="payment__in__container">
          <p className="service__type">Egyenlegfeltöltés</p>
          <p className='price'>+ 2000Ft</p>
        </div>*/}
        <div className="grey-line"></div>
        <div className="data__button__container">
          <div className="balance_2_container">
            <p className="balance">Egyenleged: <span id="coin"></span>Ft</p>
          </div>

          <div onClick={onClickEgyenlegfeltoltesone} className="payment__button__container">
            <p className="payment__button__text">Egyenlegfeltöltés</p>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  )
}

export default Egyenlegem;