import React from 'react'
import './ab.scss'
import firstcard from '../../assets/images/hardver_uj.png'
import secondcard from '../../assets/images/support_uj.png'
import thirdcard from '../../assets/images/arazas_uj.png'
import fourthcard from '../../assets/images/supportsss.png'
import fifthcard from '../../assets/images/szerver.png'
import sixthcard from '../../assets/images/szervergep.png'
import { useNavigate } from 'react-router-dom'



const Buttons = (props) => {

    if (props.type == "abcard") {
        return (
          <img  className='about__card' src={firstcard} alt="kep1"></img>
        )
    }
    if (props.type == "abcardtwo") {
        return (
          <img className='about__card' src={secondcard} alt="kep2"></img>
        )
    }
    if (props.type == "abcardthree") {
        return (
          <img className='about__card' src={thirdcard} alt="kep3"></img>
        )
    }
    if (props.type == "abcardfour") {
        return (
          <img className='about__card__small' src={fourthcard} alt="kep4"></img>
        )
    }
    if (props.type == "abcardfive") {
        return (
          <img className='about__card__small' src={fifthcard} alt="kep5"></img>
        )
    }
    if (props.type == "abcardsix") {
        return (
          <img className='about__card__small' src={sixthcard} alt="kep6"></img>
        )
    }
  return (
    <p>nem tudtuk betölteni a képeket.</p>
  )
}

export default Buttons
