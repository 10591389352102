import React from 'react'
import './buttons.scss'
import navlogin from '../../assets/images/login.png'
import login from '../../assets/images/login.png'
import regi from '../../assets/images/registration.png'
import { useNavigate } from 'react-router-dom'

const Navbuttons = (props) => {
  const navigate = useNavigate();
  const onClickLogin = () => {
      navigate("/auth/login")
  }

  const onClickRegistration = () => {
      navigate("/auth/registration")
  }

  const onClickUgyfelkapu = () => {
    navigate("/ugyfelkapu")
}
    if (props.type == "navlogin") {
        return (
          <button onClick={onClickUgyfelkapu} className='navloginButton'><img className='nav__container__logo' src={navlogin} alt="Bejelentkezés"></img>Bejelentkezés</button>
        )
    }
    if (props.type == "navugyfelkapu") {
      return (
        <button onClick={onClickUgyfelkapu} className='navugyfelkapuButton'><img className='nav__container__logo' src={navlogin} alt="Ügyfélkapu"></img>Ügyfelkapu</button>
      )
    }
    if (props.type == "login") {
      return (
        <button onClick={onClickUgyfelkapu} className='loginButton'><img className='nav__container__logo' src={login} alt="Bejelentkezés"></img>Bejelentkezés</button>
      )
    }
    if (props.type == "reg") {
      return (
        <button onClick={onClickRegistration} className='regButton'><img className='nav__container__logo__reg' src={regi} alt="Regisztráció"></img>Regisztráció</button>
      )
    }
  return (
    <button>Ez egy gomb css nélkül</button>
  )
}

export default Navbuttons
