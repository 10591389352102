import React, { useState, useEffect } from 'react';
import hxlogo from '../../assets/images/hxlogo.png';
import phonenav from '../../assets/images/apps-add.png';
import './navbar.scss';
import Buttons from '../../components/Buttons/Buttons.jsx';
import { useNavigate } from 'react-router-dom';

const UgyfelkapuNavbar = () => {
    const [isPhoneNavOpen, setIsPhoneNavOpen] = useState(false);

    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth >= 1100) {
                setIsPhoneNavOpen(false);
            }
        };
        window.addEventListener('resize', handleResize);
        handleResize(); 
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const togglePhoneNav = (e) => {
        e.preventDefault();
        setIsPhoneNavOpen(!isPhoneNavOpen);
    };

    const navigate = useNavigate();

    const onClickMainpage = () => {
        navigate("/");
    };

    const handleScrollToSection = () => {
        onClickMainpage();
        setTimeout(() => {
            const section = document.getElementById('services-scroll');
            if (section) {
                section.scrollIntoView({ behavior: 'smooth' });
            }
        }, 1);
    };

    const baszas = () => {
        onClickMainpage();
        setTimeout(() => {
            const section = document.getElementById('miertmi-scroll');
            if (section) {
                section.scrollIntoView({ behavior: 'smooth' });
            }
        }, 1);
    };

    const onClickAboutUs = () => {
        navigate("/rolunk");
    };

const onClickServerList = () => {
    window.open("https://szerverek.hexaverse.hu/", "_blank");
};
    

    return (
        <div>
            <div className='ugyfelkapu-nav'>
                <nav className='ugyfelkapu-nav__container'>
                    <ul className='ugyfelkapu-nav__container__elements'>
                        <div className='ugyfelkapu-nav__container__elements__logo'>
                            <img className='ugyfelkapu-nav__container__logo' src={"https://cdn.hexaverse.hu/hxlogo.png"} alt="HexaVerse logo" />
                        </div>
                        <li className='ugyfelkapu-nav__container__elements__item'>
                            <a onClick={onClickMainpage}>Főoldal</a>
                        </li>
                        <li className='ugyfelkapu-nav__container__elements__item'>
                            <a onClick={handleScrollToSection}>Szolgáltatások</a>
                        </li>
                        <li className='ugyfelkapu-nav__container__elements__item'>
                            <a href="https://dc.hexaverse.hu/" target="_blank" rel="noopener noreferrer">Kapcsolat</a>
                        </li>
                        <li className='ugyfelkapu-nav__container__elements__item'>
                            <a onClick={onClickServerList}>Szerverlista</a>
                        </li>
                        <li className='ugyfelkapu-nav__container__elements__item'>
                            <a onClick={onClickAboutUs}>Rólunk</a>
                        </li>
                        <li className='ugyfelkapu-nav__container__elements__item'>
                        <a onClick={baszas}>Miért mi?</a>
                        </li>
                        <Buttons type="navugyfelkapu" />
                    </ul>
                </nav>
            </div>

            <div id='ugyfelkapu-phone-nav-toggle' onClick={togglePhoneNav} className='ugyfelkapu-phone__nav__toggle'>
                <img className='ugyfelkapu-phone__nav__toggle__image' src={phonenav} alt="Telefonos Navbar" />
            </div>

            <div className={`ugyfelkapu-phone__nav ${isPhoneNavOpen ? 'ugyfelkapu-phone__nav--open' : ''}`}>
                <nav className='ugyfelkapu-phone__nav__container'>
                    <button className='ugyfelkapu-phone__nav__close' onClick={togglePhoneNav}>X</button>
                    <ul className='ugyfelkapu-phone__nav__container__elements'>
                        <div className='ugyfelkapu-phone__nav__container__elements__logo'>
                            <img className='ugyfelkapu-phone__nav__container__logo' src={"https://cdn.hexaverse.hu/hxlogo.png"} alt="HexaVerse logo" />
                        </div>
                        <li className='ugyfelkapu-phone__nav__container__elements__item'>
                            <a onClick={onClickMainpage}>Főoldal</a>
                        </li>
                        <li className='ugyfelkapu-phone__nav__container__elements__item'>
                            <a onClick={handleScrollToSection}>Szolgáltatások</a>
                        </li>
                        <li className='ugyfelkapu-phone__nav__container__elements__item'>
                            <a href="https://dc.hexaverse.hu/" target="_blank" rel="noopener noreferrer">Kapcsolat</a>
                        </li>
                        <li className='ugyfelkapu-phone__nav__container__elements__item'>
                            <a onClick={onClickServerList}>Szerverlista</a>
                        </li>
                        <li className='ugyfelkapu-phone__nav__container__elements__item'>
                            <a onClick={onClickAboutUs}>Rólunk</a>
                        </li>
                        <li className='ugyfelkapu-phone__nav__container__elements__item'>
                            <a onClick={baszas}>Miért mi?</a>
                        </li>
                        <li className='ugyfelkapu-phone__nav__container__elements__item'>
                            <a href="/ugyfelkapu">Ügyfélkapu</a>
                        </li>
                    </ul>
                </nav>
            </div>
        </div>
    );
};

export default UgyfelkapuNavbar;
