import React, { useState, useEffect } from 'react';
import './RegPage.scss';
import hxlogo from '../../assets/images/hxlogo.png';
import { useNavigate } from 'react-router-dom';

const RegPage = () => {
  const [formData, setFormData] = useState({
    username: '',
    email: '',
    fullName: '',
    password: '',
    confirmPassword: '',
    street: '',
    city: '',
    postalCode: '',
  });

  const [error, setError] = useState('');
  const [stage, setStage] = useState(1); // Track the current stage
  const [successReg, setSuccessReg] = useState(false);
  const [seconds, setSeconds] = useState(3); // Timer for redirection


  const navigate = useNavigate();
    useEffect(() => {
        if (seconds <= 0) {
            onSuccessReset();
        }
    }, [seconds]);
    const onSuccessReset = () => {
        navigate("/ugyefelkapu");
    }

  useEffect(() => {
    let timer;
    if (successReg && seconds > 0) {
      timer = setTimeout(() => setSeconds((prev) => prev - 1), 1000);
    } else if (successReg && seconds === 0) {
      navigate('/auth/login');
    }
    return () => clearTimeout(timer);
  }, [successReg, seconds, navigate]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleNextStage = () => {
    if (stage === 1) {
      if (!formData.email.includes('@')) {
        setError('Érvényes email címet adjon meg.');
        return;
      } else if (formData.username.length < 4) {
        setError('A felhasználónév legalább 4 karakter hosszú legyen.');
        return;
      } else if (formData.username.length > 16) {
        setError('A felhasználónév maximum 16 karakter hosszú legyen.');
        return;
      } else if (formData.fullName.length < 2) {
        setError('A teljes név legalább 2 karakter hosszú legyen.');
        return;
      }
    }

    setError('');
    setStage((prevStage) => prevStage + 1);
  };

  const handlePreviousStage = () => {
    setError('');
    setStage((prevStage) => prevStage - 1);
  };

  const onReg = () => {
    const myHeaders = new Headers();
    myHeaders.append('Content-Type', 'application/json');

    const fullAddress = `${formData.postalCode} ${formData.city}, ${formData.street}`;

    const raw = JSON.stringify({
      username: formData.username,
      password: formData.password,
      email: formData.email,
      firstname: formData.fullName.split(' ')[1] || '',
      lastname: formData.fullName.split(' ')[0],
      address: fullAddress,
    });

    const requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow',
    };

    fetch('https://api.hexaverse.hu/api/v1/auth/register', requestOptions)
      .then((response) => {
        if (!response.ok) {
          return response.json().then((error) => {
            if (error.message === 'Username already exists') {
              setError('A felhasználónév már foglalt.');
            } else if (error.message === 'Username or email already exists') {
              setError('A felhasználónév vagy az email cím már foglalt.');
            } else if (error.message === 'Email already exists') {
              setError('Az email cím már foglalt.');
            } else if (error.message === 'All fields are required') {
              setError('Az összes adatot meg kell adni.');
            }
          });
        } else {
          setSuccessReg(true);
        }
      })
      .catch((error) => console.error(error));
  };

  const handleRegister = (e) => {
    e.preventDefault();
    const specialCharRegex = /^[A-Za-z0-9@#$%^&+=!]*$/;
    const hasAccents = /[áéíóöőúüűÁÉÍÓÖŐÚÜŰ]/;

    if (formData.password.length < 8) {
      setError('A jelszónak legalább 8 karakter hosszúnak kell lennie.');
      return;
    } else if (formData.password !== formData.confirmPassword) {
      setError('A két jelszó nem egyezik meg.');
      return;
    } else if (formData.password.includes(' ')) {
      setError('A jelszó nem tartalmazhat szóközt.');
      return;
    } else if (!specialCharRegex.test(formData.password)) {
      setError('A jelszó csak betűket, számokat és speciális karaktereket tartalmazhat (@, #, $, stb.).');
      return;
    } else if (hasAccents.test(formData.password)) {
      setError('A jelszó nem tartalmazhat ékezetes karaktereket (pl. á, é, í).');
      return;
    } else {
      setError('');
      onReg();
    }
  };

  return (
    <div className="reg-container">
      <div className="logo-section">
        <img src={"https://cdn.hexaverse.hu/hxlogo.png"} alt="HexaVerse Logo" className="logo" />
      </div>
      <div className="form-divider"></div>
      <div className={`reg-box stage-${stage}`}>
        <h1 className="reg-title">Regisztráció</h1>

        <form className="reg-form" onSubmit={handleRegister}>
          {error && <ErrorBox errors={error} />}
          {stage === 1 && (
            <div className="stage active">
              <div className="input-group">
                <label>Email</label>
                <input
                  type="email"
                  name="email"
                  placeholder="Email"
                  value={formData.email}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="input-group">
                <label>Felhasználónév</label>
                <input
                  type="text"
                  name="username"
                  placeholder="Felhasználónév"
                  value={formData.username}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="input-group">
                <label>Teljes név</label>
                <input
                  type="text"
                  name="fullName"
                  placeholder="Teljes név"
                  value={formData.fullName}
                  onChange={handleChange}
                  required
                />
              </div>
              <button type="button" className="continue-button" onClick={handleNextStage}>
                Tovább
              </button>
            </div>
          )}

          {stage === 2 && (
            <div className="stage active">
              <div className="input-group">
                <label>Jelszó</label>
                <input
                  type="password"
                  name="password"
                  placeholder="Jelszó"
                  value={formData.password}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="input-group">
                <label>Jelszó újra</label>
                <input
                  type="password"
                  name="confirmPassword"
                  placeholder="Jelszó újra"
                  value={formData.confirmPassword}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="button-group">
                <button type="button" className="back-button" onClick={handlePreviousStage}>
                  Vissza
                </button>
                <button type="submit" className="reg-button">
                  Regisztráció
                </button>
              </div>
            </div>
          )}
        </form>
      </div>
    </div>
  );
};

function ErrorBox({ errors }) {
  return (
    <div className="errorbox">
      <p>{errors}</p>
    </div>
  );
}

export default RegPage;
