import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import MainPage from './pages/MainPage';
import Minecraft from './pages/services/Minecraft';
import Discord from './pages/services/Discord';
import Tf2classic from './pages/services/TFC';
import Mta from './pages/services/MTA';
import Cs2 from './pages/services/Cs2';
import Nodejs from './pages/services/Nodejs';
import GMOD from './pages/services/Garrysmod';
import Sonsoftheforest from './pages/services/Sonsoftheforest';
import Rust from './pages/services/Rust';
import VPS from './pages/services/VPS';
import Ark from './pages/services/Ark';
import Terraria from './pages/services/Terraria';
import RegPage from './pages/auth/RegPage';
import LoginPage from './pages/auth/LoginPage';
import ForgotPassword from './pages/auth/ForgotPassword.jsx';
import PasswordReset from './pages/auth/PasswordReset';
import Landing from './pages/ugyfelkapu/Landing';
import AdminLanding from './pages/ugyfelkapu/Adminlanding';
import StripePage from './pages/payment/StripePage';
import PaypalPage from './pages/payment/PaypalPage';
import Szolgaltatasom from './pages/ugyfelkapu/Szolgaltatasaim.jsx';
import Egyenlegem from './pages/ugyfelkapu/Egyenlegem.jsx';
import Egyenlegfeltoltes from './pages/ugyfelkapu/Egyenlegfeltoltes.jsx';
import Servereditor from './pages/ugyfelkapu/Serverrenew.jsx';
import Verziok from './pages/aboutus/Verziok.jsx';
import Rolunk from './pages/aboutus/About.jsx';
import Coupon from './pages/ugyfelkapu/Coupon.jsx';

const isValidToken = (token) => {
  try {
    const payload = JSON.parse(atob(token.split('.')[1]));
    const currentTime = Math.floor(Date.now() / 1000);
    return payload.exp > currentTime;
  } catch (error) {
    return false;
  }
};

const AppRoutes = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);

  useEffect(() => {
    const token = localStorage.getItem("F49w'2jTP:mE6C/fJQXA,G");
    const isAdminBoolean = localStorage.getItem("zw+J2vCG/n9KQVxfpE4N;5") === "true";

    if (token && isValidToken(token)) {
      setIsAuthenticated(true);

      if (isAdminBoolean) {
        setIsAdmin(true);
      } else {
        setIsAdmin(false);
      }

    } else {
      setIsAuthenticated(false);
    }
  }, []);

  return (
    <Routes>
      <Route path="/auth">
        <Route path="login" element={<LoginPage />} />
        <Route path="registration" element={<RegPage />} />
        <Route path="passwordreset" element={<PasswordReset />} />
        <Route path="forgotpassword" element={<ForgotPassword />} />
      </Route>
      <Route path="/" element={<MainPage />} />
      <Route path="/game/ark" element={<Ark />} />
      <Route path="/game/minecraft" element={<Minecraft />} />
      <Route path="/game/discord" element={<Discord />} />
      <Route path="/game/tf2classic" element={<Tf2classic />} />
      <Route path="/game/mta" element={<Mta />} />
      <Route path="/game/cs2" element={<Cs2 />} />
      <Route path="/game/nodejs" element={<Nodejs />} />
      <Route path="/game/garrysmod" element={<GMOD />} />
      <Route path="/game/sonsoftheforest" element={<Sonsoftheforest />} />
      <Route path="/game/rust" element={<Rust />} />
      <Route path="/game/vps" element={<VPS />} />
      <Route path="/game/terraria" element={<Terraria />} />
      <Route path="/payment/stripe/complete" element={<StripePage />} />
      <Route path="/payment/paypal/complete" element={<PaypalPage />} />
      <Route path="/verziok" element={<Verziok />} />
      <Route path="/rolunk" element={<Rolunk />} />

      {isAuthenticated ? (
        <>
          <Route path="/ugyfelkapu" element={<Landing />} />
          <Route path="/szolgaltatasaim" element={<Szolgaltatasom />} />
          <Route path="/egyenlegem" element={<Egyenlegem />} />
          <Route path="/egyenlegfeltoltes" element={<Egyenlegfeltoltes />} />
          <Route path="/servereditor" element={<Servereditor />} />
        </>
      ) : (
        <Route path="*" element={<LoginPage />} />
      )}
      {isAdmin ? (
        <>
          <Route path="/admin" element={<AdminLanding />} />
          <Route path="/admin/coupon" element={<Coupon />} />
        </>
      ) : (
        <Route path="*" element={<LoginPage />} />
      )}
    </Routes>
  );
};

const App = () => {
  return (
    <Router>
      <AppRoutes />
    </Router>
  );
};

export default App;
