import React, { useEffect, useRef, useState } from 'react';
import Navbar from '../components/Navbar/Navbar.jsx';
import Bg1 from '../components/Bg1/Bg1.jsx';
import '../components/Typing/typing.scss';
import '../assets/scss/Base/main.scss';
import Buttons from '../components/Buttons/Buttons.jsx';
import Aboutcards from '../components/Aboutcards/Ab.jsx';
import Cards from '../components/Cards/Cards.jsx';
import DropdownMenu from '../components/Dropdown/Droprow.jsx';
import Footer from '../components/Footer/Footer.jsx';

const Checkbox = ({ id, label, checked, onChange }) => {
  return (
    <label className={`checkbox__label ${checked ? 'checked' : ''}`}>
      <input
        type="checkbox"
        id={id}
        checked={checked}
        onChange={() => onChange(id)}
        className="checkbox__input"
      />
      {label}
    </label>
  );
};

const MainPage = () => {
  const typingIntervalRef = useRef(null);
  const [selectedTags, setSelectedTags] = useState([]);
  const [filteredCards, setFilteredCards] = useState([]);
  const {v4: uuidv4} = require('uuid');


  useEffect(() => {
    const wordElement = document.getElementById('typing-effect');
    const words = ["professzionális", "megbízható", "gyors", "testreszabható", "biztonságos", "rugalmas", "olcsó", "innovatív"];
    let wordIndex = 0;
    let charIndex = 0;
    let isDeleting = false;

    function type() {
      const currentWord = words[wordIndex];

      if (isDeleting) {
        charIndex--;
      } else {
        charIndex++;
      }

      wordElement.textContent = currentWord.substring(0, charIndex);

      let typingSpeed = isDeleting ? 75 : 150;

      if (!isDeleting && charIndex === currentWord.length) {
        typingSpeed = 1000; 
        isDeleting = true;
      } else if (isDeleting && charIndex === 0) {
        typingSpeed = 400; 
        isDeleting = false;
        wordIndex = (wordIndex + 1) % words.length;
      }

      typingIntervalRef.current = setTimeout(type, typingSpeed);
    }

    typingIntervalRef.current = setTimeout(type, 1000);

    return () => clearTimeout(typingIntervalRef.current);
  }, []);

  const handleTagChange = (tag) => {
    setSelectedTags((prevTags) =>
      prevTags.includes(tag)
        ? prevTags.filter(t => t !== tag)
        : [...prevTags, tag]
    );
  };
  const isDeviceIdExist = localStorage.getItem("deviceid")
  if(!isDeviceIdExist){
    localStorage.setItem("deviceid", uuidv4())
  }

  return (
    <div className="main-page-background">
      <Bg1 />
      <Navbar />
      <div className='typing__container'>
        <h2 className="typing__container__cim">
          <span className="typing__container__cim">Egy</span>
          <span id="typing-effect" className="pink"> innovatív</span>
          <span className="typing__container__cim"> hosting szolgáltató</span>
        </h2>
        <p className="typing__container__alcim">Gamerek által, gamereknek készített, olcsó és minőségi játékszerverek.</p>
      </div>

      <div className='form_button'>
        <Buttons type="reg" />
        <Buttons type="login" />
      </div>

      <div className='ab__card_container' id="miertmi-scroll">
        <Aboutcards type="abcard" />
        <Aboutcards type="abcardtwo" />
        <Aboutcards type="abcardthree" />
      </div>

      <h2 className="typing__container__cim" id="statistics-scroll">Statisztika</h2>
      <p className="typing__container__alcim">Az alábbi adatok mutatják felhasználóink bérelt szerverei, szervergépeink illetve regisztrált felhasználóink számát.</p>
      <div className='ab__card_container__smaller'>
        <Aboutcards type="abcardfour" />
        <Aboutcards type="abcardsix" />
        <Aboutcards type="abcardfive" />
      </div>

      <h2 className="typing__container__cim" id="services-scroll">Szolgáltatások</h2>
      <p className="typing__container__alcim">Választékos és megbízható szolgáltatásaink mindent igényt kielégítenek.</p>

      <div className='game__card_container'>
        <Cards type="gamecard" />
        <Cards type="gamecardtwo" />
        <Cards type="gamecardthree" />
        <Cards type="gamecardfour" />
        {/*<Cards type="gamecardfive" />*/}
        <Cards type="gamecardsix" />
        <Cards type="gamecardseven" />
        <Cards type="gamecardnine" />
        <Cards type="gamecardtwelve" />
        <Cards type="gamecardthirteen" />
        <Cards type="gamecardten" />
        <Cards type="gamecardeleven" />
      </div>

      <DropdownMenu />
      <Footer />
    </div>
  );
};
export default MainPage;
