import React, { useState } from 'react';
import './Login.scss';
import hxlogo from '../../assets/images/hxlogo.png';
import { useNavigate } from 'react-router-dom';
import { setUserData, setService } from '../../data/DataFunctions.js';
const { v4: uuidv4 } = require('uuid');

const LoginPage = () => {
  const [formData, setFormData] = useState({
    username: '',
    password: ''
  });
  const [error, setError] = useState('');

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const navigate = useNavigate();

  const onLogin = () => {
    let deviceId = localStorage.getItem("deviceid");
    if (!deviceId) {
      deviceId = uuidv4();
      localStorage.setItem("deviceid", deviceId);
    }

    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    const raw = JSON.stringify({
      "username": formData.username,
      "password": formData.password,
      "device_id": deviceId
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow"
    };

    fetch("https://api.hexaverse.hu/api/v1/auth/login", requestOptions)
      .then(async (response) => {
        const responseJson = await response.json();
        if (!response.ok) {
          if (["err_pass_or_user_or_device", "invaild_username_or_pass", "err_internal", "err_session_exist"].includes(responseJson.error)) {
            setError("Helytelen felhasználónév vagy jelszó.");
            return;
          }
        }

        if (responseJson.token) {
          localStorage.setItem("F49w'2jTP:mE6C/fJQXA,G", responseJson.token);
          setUserData({
            username: formData.username,
            coin: responseJson.coin
          });
          console.log(responseJson)

          if (responseJson.servicesList) {
            responseJson.servicesList.forEach(element => {
              setService({
                service_id: element.service_id,
                service_type: element.service_type,
                service_name: element.service_name,
                memory: element.memory,
                disk: element.disk,
                cpu: element.cpu,
                expires_at: element.expiresId
              });
            });
          }

          if (responseJson.admin) {
            localStorage.setItem("zw+J2vCG/n9KQVxfpE4N;5", true);
          }

          // Ellenőrizzük, hogy már frissítettük-e az oldalt
          if (!sessionStorage.getItem("refreshed")) {
            sessionStorage.setItem("refreshed", "true");
            navigate("/ugyfelkapu");
            window.location.reload(); // Frissítjük az oldalt a navigáció után
          } else {
            sessionStorage.removeItem("refreshed");  // Eltávolítjuk a jelzést a sessionStorage-ból
          }
        }
      })
      .catch((error) => {
        console.error(error);
        setError("Valami hiba történt. Próbáld újra később!");
      });
  };

  return (
    <div className="login-container">
      <div className="logo-section">
        <img src={hxlogo} alt="HexaVerse Logo" className="logo" />
      </div>
      <div className="form-divider"></div>
      <div className="login-box">
        <h1 className="login-title">Bejelentkezés</h1>
        <div className="login-form" onKeyDown={(e) => {
          if (e.key === "Enter") onLogin();
        }}>
          <div className="input-group">
            <label>Felhasználónév</label>
            <input
              type="text"
              name="username"
              placeholder="Felhasználónév"
              value={formData.username}
              onChange={handleChange}
              required
            />
          </div>
          <div className="input-group">
            <label>Jelszó</label>
            <input
              type="password"
              name="password"
              placeholder="Jelszó"
              value={formData.password}
              onChange={handleChange}
              required
            />
          </div>

          {error && <p className="error-message">{error}</p>}

          <div className="login-options">
            <label>
              <input type="checkbox" name="remember" />
              Bejelentkezve maradok
            </label>
            <a href="/auth/forgotpassword">Elfelejtettem a jelszavam</a>
          </div>

          <button onClick={onLogin} type="submit" className="login-button">
            Bejelentkezés
          </button>

          <p className="signup-link">
            Nincs még fiókod? <a href="/auth/registration">Regisztrálj!</a>
          </p>
        </div>
      </div>
    </div>
  );
};

export default LoginPage;
