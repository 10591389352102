import React from 'react';
import './cards.scss';
import mccard from '../../assets/images/mc-card.png';
import dccard from '../../assets/images/discord-cards.png';
import tfccard from '../../assets/images/teamfortress2classic-card.png';
import terrcard from '../../assets/images/terraria-card.png';
import cscard from '../../assets/images/cs2-card.png';
import mtacard from '../../assets/images/MTA-card.png';
import nodejscard from '../../assets/images/nodejs-card.png';
import gmodcard from '../../assets/images/garrysmod-card.png';
import snsforestcard from '../../assets/images/sonsoftheforest-card.png';
import vpscard from '../../assets/images/vps-card.png';
import rustcard from '../../assets/images/rust-card.png';
import arkcard from '../../assets/images/ark.png';
import askuscard from '../../assets/images/ask-us-card.png';
import { useNavigate } from 'react-router-dom';

const Buttons = (props) => {
    const navigate = useNavigate();
    
    const scrollToTop = () => {
        window.scrollTo(0, 0);
    };

    const onClickArk = () => {
        navigate("/game/ark");
        scrollToTop();
    };

    const onClickMinecraft = () => {
        navigate("/game/minecraft");
        scrollToTop();
    };

    const onClickDc = () => {
        navigate("/game/discord");
        scrollToTop();
    };

    const onClickTfc = () => {
        navigate("/game/tf2classic");
        scrollToTop();
    };

    const onClickMta = () => {
        navigate("/game/mta");
        scrollToTop();
    };

    const onClickTerraria = () => {
        navigate("/game/terraria");
        scrollToTop();
    };

    const onClickCs2 = () => {
        navigate("/game/cs2");
        scrollToTop();
    };

    const onClickNodejs = () => {
        navigate("/game/nodejs");
        scrollToTop();
    };

    const onClickGarrysmod = () => {
        navigate("/game/garrysmod");
        scrollToTop();
    };

    const onClickSonsforest = () => {
        navigate("/game/sonsoftheforest");
        scrollToTop();
    };

    const onClickRust = () => {
        navigate("/game/rust");
        scrollToTop();
    };

    const onClickvps = () => {
        navigate("/game/vps");
        scrollToTop();
    };

    const onClickaskus = () => {
        window.open('https://dc.hexaverse.hu', '_blank');
    };

    const renderCard = (cardType, clickHandler, cardImage, altText) => {
        return <img onClick={clickHandler} className='game__card' src={cardImage} alt={altText}></img>;
    };

    switch (props.type) {
        case "gamecard":
            return renderCard("gamecard", onClickMinecraft, mccard, "kep1");
        case "gamecardtwo":
            return renderCard("gamecardtwo", onClickDc, dccard, "kep2");
        case "gamecardthree":
            return renderCard("gamecardthree", onClickTfc, tfccard, "kep3");
        case "gamecardfour":
            return renderCard("gamecardfour", onClickTerraria, terrcard, "kep4");
        case "gamecardfive":
            return renderCard("gamecardfive", onClickCs2, cscard, "kep5");
        case "gamecardsix":
            return renderCard("gamecardsix", onClickMta, mtacard, "kep6");
        case "gamecardseven":
            return renderCard("gamecardseven", onClickNodejs, nodejscard, "kep7");
        case "gamecardeight":
            return renderCard("gamecardeight", onClickGarrysmod, gmodcard, "kep8");
        case "gamecardnine":
            return renderCard("gamecardnine", onClickSonsforest, snsforestcard, "kep9");
        case "gamecardten":
            return renderCard("gamecardten", onClickvps, vpscard, "kep10");
        case "gamecardeleven":
            return renderCard("gamecardeleven", onClickaskus, askuscard, "kep11");
        case "gamecardtwelve":
            return renderCard("gamecardtwelve", onClickArk, arkcard, "kep12");
        case "gamecardthirteen":
            return renderCard("gamecardthirteen", onClickRust, rustcard, "kep13");
        default:
            return <p>nem tudtuk betölteni a képeket.</p>;
    }
};

export default Buttons;
