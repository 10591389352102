import React, { useState, useEffect } from 'react';
import './Gsbase.scss';
import Navbar from '../../components/Navbar/Navbar.jsx';
import Footer from '../../components/Footer/Footer.jsx';
import dc_banner from '../../assets/images/discord-banner.png';

const { buyService } = require("../../payment/paymentFunctions.js")

// Define the available versions for Discord
const Discord = () => {
  const [ram, setRam] = useState(0.5);
  const [storage, setStorage] = useState(15);
  const [cpu, setCpu] = useState(1.5);
  const [serverType, setServerType] = useState('Python 9');
  const [serverName, setServerName] = useState('');
  const [couponCode, setCouponCode] = useState('');
  const [isTypeOpen, setIsTypeOpen] = useState(false);
  const [isVersionOpen, setIsVersionOpen] = useState(false);
  const [expirationDate, setExpirationDate] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [fadeOut, setFadeOut] = useState(true);
  const [messageType, setMessageType] = useState('');

  useEffect(() => {
    const currentDate = new Date();
    currentDate.setDate(currentDate.getDate() + 30);
    setExpirationDate(currentDate.toLocaleDateString('hu-HU'));
  }, []);

  const calculateCost = () => {
    const ramCost = ram <= 8 ? ram * 500 : (8 * 500);
    const storageCost = storage > 15 ? (storage - 15) * 25 : 0;
    const cpuCost = cpu <= 1.5 ? 0 : Math.ceil((cpu - 1.5) * 2) * 250;

    return ramCost + storageCost + cpuCost;
  };

  const toggleTypeDropdown = () => setIsTypeOpen(!isTypeOpen);
  const toggleVersionDropdown = () => setIsVersionOpen(!isVersionOpen);

  const handleTypeSelection = (type) => {
    setIsTypeOpen(false);
  };

  const handleVersionSelection = (version) => {
    setIsVersionOpen(false);
  };

  const handleBuy = () =>{
    console.log("asd")
    buyService("python", serverName, ram * 1024, storage * 1024, cpu * 100, couponCode)
  }
  return (
    <div>
      <Navbar />
      <div className='gs__container'>
        <img className='gs__banner' src={dc_banner} alt="kep1" />
      </div>

      <div className='config-summary-container'>
        <div className='configuration-section'>
          <h1>Szerver konfiguráció</h1>

          <div className="slider-wrapper">
            <label className='white-text'>
              Memória <span className='sublabel'>- {ram}GB</span>
            </label>
            <div className='slider-container'>
              <input
                type="range"
                min="0.5"
                max="4"
                step="0.5"
                value={ram}
                onChange={(e) => setRam(parseFloat(e.target.value))}
                className='slider discord-slider_a'
              />
            </div>
            <div className='slider-container-buttons'>
              <button onClick={() => setRam(Math.max(0.5, ram - 0.5))}>-</button>
              <button onClick={() => setRam(Math.min(4, ram + 0.5))}>+</button>
            </div>
          </div>

          <div className="slider-wrapper">
            <label className='white-text'>
              Tárhely <span className='sublabel'>- {storage}GB</span>
            </label>
            <div className='slider-container'>
              <input
                type="range"
                min="15"
                max="20"
                value={storage}
                onChange={(e) => setStorage(parseInt(e.target.value))}
                className='slider discord-slider_b'
              />
            </div>
            <div className='slider-container-buttons'>
              <button onClick={() => setStorage(Math.max(15, storage - 1))}>-</button>
              <button onClick={() => setStorage(Math.min(20, storage + 1))}>+</button>
            </div>
          </div>

          <div className="slider-wrapper">
            <label className='white-text'>
              Processzor szálak <span className='sublabel'>- {cpu}</span>
            </label>
            <div className='slider-container'>
              <input
                type="range"
                min="1.5"
                max="3"
                step="0.5"
                value={cpu}
                onChange={(e) => setCpu(parseFloat(e.target.value))}
                className='slider discord-slider_c'
              />
            </div>
            <div className='slider-container-buttons'>
              <button onClick={() => setCpu(Math.max(1.5, cpu - 0.5))}>-</button>
              <button onClick={() => setCpu(Math.min(3, cpu + 0.5))}>+</button>
            </div>
          </div>

          <div className="grey-line"></div>

          <div className='details'>
            <label>Részletek</label>
            <input
              type="text"
              placeholder="Szerver neve"
              value={serverName}
              onChange={(e) => setServerName(e.target.value)}
              className='server-name-input'
            />
          </div>

          <div className="grey-line"></div>
          <div className='tax-note'>! Az árak alanyi adómentesek !</div>
        </div>

        <div className='discord-divider'></div>

        <div className='summary-section'>
          <h1>Összegzés</h1>
          <div className="grey-line"></div>
          <div className='summary-content'>
            <p className='white-text'>Memória <span className='sublabel'>- {ram}GB</span></p>
            <p className='white-text'>Tárhely <span className='sublabel'>- {storage}GB</span></p>
            <p className='white-text'>Processzor szálak <span className='sublabel'>- {cpu}</span></p>
            <div className="grey-line"></div>
            <input
              type="text"
              placeholder="Kupon"
              value={couponCode}
              onChange={(e) => setCouponCode(e.target.value)}
              className='coupon-input'
            />
            <div className="grey-line"></div>
            <p className='total-cost'>Összesen: <span className='boldlabel'>{calculateCost()} Ft</span> <span className='sublabel'> / Hó</span></p>
            <span className='sublabel'>Lejárat - {expirationDate}</span>
            <div className="grey-line"></div>
            <button onClick={handleBuy} className='rent-button'>Szerver bérlése</button>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default Discord;
