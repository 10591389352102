import React, { useState, useEffect } from 'react';
import Navbar from '../../components/Navbar/Navbar.jsx';
import Footer from '../../components/Footer/Footer.jsx';
import './szolgaltatasaim.scss';
import { useNavigate } from 'react-router-dom';
import { setService } from '../../data/DataFunctions.js';

const Szolgaltatasaim = () => {
  const [uuids, setUuids] = useState([]);
  const navigate = useNavigate();

  const handleServerrenew = () => {
    navigate("https://panel.hexaverse.hu");
  };

  useEffect(() => {
    const fetchData = async () => {
      const myHeaders = new Headers();
      myHeaders.append("Authorization", `Bearer ${localStorage.getItem("F49w'2jTP:mE6C/fJQXA,G")}`);

      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        redirect: "follow",
      };

      try {
        const response = await fetch("https://api.hexaverse.hu/api/v1/services/get", requestOptions);
        const responseJson = await response.json();

        if (responseJson.servicesList) {

          const updatedUuids = [];
          responseJson.servicesList.forEach((service) => {
            setService({
              service_id: service.service_id,
              service_type: service.service_type,
              service_name: service.service_name,
              memory: service.memory,
              disk: service.disk,
              cpu: service.cpu,
              expires_at: service.exp,
            });

            updatedUuids.push(service.service_id);
          });

          setUuids(updatedUuids);
          
          for (let i = 0; i < localStorage.length; i++) {
            const key = localStorage.key(i);

            const uuidPattern = /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/;
            const importantKeys = [
              "F49w'2jTP:mE6C/fJQXA,G",
              "zw+J2vCG/n9KQVxfpE4N;5",
              "username",
              "coin",
              "deviceid",
            ];

            if (
              uuidPattern.test(key.split('/')[0]) &&
              !updatedUuids.includes(key.split('/')[0]) &&
              !importantKeys.includes(key)
            ) {
              localStorage.removeItem(key);
            }
          }
        }
      } catch (error) {
        console.error("Fetch error:", error);
      }
    };

    fetchData();
  
  }, []); 

  const handleScrollToSection = () => {
    navigate("/");
    setTimeout(() => {
      const section = document.getElementById("services-scroll");
      if (section) {
        section.scrollIntoView({ behavior: "smooth" });
      }
    }, 1);
  };

  return (
    <div>
      <Navbar />
      <div className="szolgaltatasaim__container">
        <div className="szolgaltatasaim__box szolgaltatasaim__box--small1">
          <h1>Szolgáltatásaim:</h1>
          <p>Itt láthatod és kezelheted a bérelt szolgáltatásaidat!</p>
          {uuids.length > 0 ? (
            uuids.map((uuid) => (
              <div key={uuid} className="active__szolgaltatasaim__container">
                <p className="services">{localStorage.getItem(`${uuid}/service_name`)}</p>
                <p className="services">{(localStorage.getItem(`${uuid}/ram`) / 1024)}GB Memória</p>
                <p className="services">{(localStorage.getItem(`${uuid}/cpu`) / 100)} CPU</p>
                <p className="services">{(localStorage.getItem(`${uuid}/disk`) / 1024)}GB Tárhely</p>
                <a onClick={() => navigate("/servereditor", {
                    state: {
                      service_name: localStorage.getItem(`${uuid}/service_name`),
                      memory: (localStorage.getItem(`${uuid}/ram`) / 1024),
                      cpu: (localStorage.getItem(`${uuid}/cpu`) / 100),
                      disk: (localStorage.getItem(`${uuid}/disk`) / 1024),
                      uuid: uuid,
                    },
                  })}
                  className="buyone__button"
                  style={{ cursor: 'pointer', textDecoration: 'none' }}
                >
                  Kezelés
                </a>

              </div>
            ))
          ) : (
            <div className="inactive__szolgaltatasaim__container">
              <p className="attention">Figyelem!</p>
              <p className="no__services__yet">Még nincsenek szolgáltatásaid</p>
              <button className="buyone__button" onClick={handleScrollToSection}>
                Bérelj egyet!
              </button>
            </div>
          )}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Szolgaltatasaim;
