import React from 'react';
import Navbar from '../../components/Navbar/Navbar.jsx';
import Footer from '../../components/Footer/Footer.jsx';
import './landingp.scss';

const Adminlanding = () => {

    const onClickaskus = () => {
        window.open('https://dc.hexaverse.hu', '_blank');
    };

    return (
      <div>
          <Navbar />
          <h1 className='welcoming__message'>Üdv újra, SigmaPog!</h1>
          <div className="landing__container__admin">
              <div className="landing__box landing__box--small1">
                <h1>Egyenlegem & Kuponjaim</h1>
                <p>Tölts fel, és kezeld egyenleged kedvedre!</p>
                <button>Kezelés</button>
              </div>
              <div className="landing__box landing__box--small2">
                <h1>Szolgáltatásaim</h1>
                <p>Itt láthatod és kezelheted a szolgáltatásaidat!</p>
                <button>Kezelés</button>
              </div>
              <div className="landing__box landing__box--small3">
                <h1>Gyors segítségkérés</h1>
                <p>Nyiss egy hibajegyet és csapatunk segítségedre siet.</p>
                <button onClick={onClickaskus}>Discord</button>
              </div>
              <div className="landing__box landing__box--wide">
                <h1>ADMIN FELÜLET</h1>
                <button>Panel</button>
              </div>
          </div>
          <Footer />
      </div>
    );
  };

export default Adminlanding;
